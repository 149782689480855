import { takeEvery, put, all, call, select } from 'redux-saga/effects'
import actionTypes from './actionTypes'
import { request } from '../../utils/request'
import { alert } from '../../utils/alert'
import { actionSignInStart, actionSignInEnd, actionSignInFailed, actionSignInSuccess, actionRefreshTokenSuccess } from './actions'
import { pushUrl } from '../../utils/url'
import history from '../../utils/history'

function* sagaSignIn(params) {
  yield put(actionSignInStart())

  const { data } = params
  const headerPost = {
    "Content-Type": "application/json",
  }

  let payload = {
    username: data?.username || "",
    password: data?.password || ""
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_AUTH}/login`
    const res = yield call(request, url, {
      method: 'POST',
      headers: headerPost,
      body: JSON.stringify(payload)
    })

    if (res?.success === true) {
      yield alert({ title: "Berhasil login", html: null })
      yield put(actionSignInSuccess(res?.result))
    } else {
      alert({ title: "Gagal login", html: res?.message })
    }
  } catch (err) {
    yield alert({ title: "Gagal login", html: null })
    yield put(actionSignInFailed())
  }

  yield put(actionSignInEnd())
}

function* sagaRefreshToken(params) {
  const { data } = params
  const header = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${data}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_AUTH}/refresh`
    const res = yield call(request, url, {
      method: 'GET',
      headers: header
    })

    if (res?.success === true) {
      yield put(actionRefreshTokenSuccess(res?.result))
    } else {
      const ask = yield alert({ title: "Gagal", html: "Gagal memperbarui sesi" })
      if (ask) {
        yield history.push({ pathname: process.env.REACT_APP_SUBDIR + "/sign-out" })
      }
    }
  } catch (err) {
    const ask = yield alert({ title: "Gagal", html: "Gagal memperbarui sesi" })
    if (ask) {
      yield history.push({ pathname: process.env.REACT_APP_SUBDIR + "/sign-out" })
    }
  }
}

function* authSaga() {
  yield all([
    takeEvery(actionTypes.SIGN_IN, sagaSignIn),
    takeEvery(actionTypes.REFRESH_TOKEN, sagaRefreshToken)
  ])
}

export default authSaga