import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { actionFetchUjianPeserta } from "../../store/ujian-peserta/actions"
import Pagination from "../../components/Table/Pagination"
import { changeUrl } from "../../utils/url"
import LimitTable from "../../components/Table/LimitTable"
import SearchTable from "../../components/Table/SearchTable"
import HeadTable from "../../components/Table/HeadTable"
import { asTimeWithLabel } from "../../utils/formatter"

const UjianPesertaIndex = (props) => {
    const { actionFetchUjianPeserta, accessToken, index } = props
    const [sort, setSort] = useState({
        by: null,
        type: null
    })
    const [search, setSearch] = useState("")
    const [searchValue, setSearchValue] = useState("")
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState("")

    useEffect(() => {
        const titlePage = "Ujian Peserta"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)
        fetchData()
    }, [])

    const fetchData = () => {
        const query = new URLSearchParams(props.location.search)

        if (query.get('page')) {
            setPage(parseInt(query.get('page')))
        } else {
            setPage(1)
        }

        if (query.get('search')) {
            setSearch(query.get('search'))
            setSearchValue(query.get('search'))
        }

        if (query.get('perPage')) {
            setPerPage(parseInt(query.get('perPage')))
        } else {
            setPerPage(10)
        }

        if (query.get('sortBy') || query.get('sortType')) {
            setSort({
                by: query.get('sortBy') || null,
                type: query.get('sortType') || null,
            })
        }

        actionFetchUjianPeserta({
            search: query.get('search') || "",
            page: parseInt(query.get('page')) || 1,
            sort: {
                by: query.get('sortBy') || null,
                type: query.get('sortType') || null,
            },
            perPage: parseInt(query.get('perPage')) || 10,
            accessToken
        })
    }

    const sortData = (field, e) => {
        let sortType = sort?.type === "desc" ? "asc" : "desc"
        setSort({
            by: field,
            type: sortType
        })
        actionFetchUjianPeserta({
            search,
            page,
            sort: {
                by: field,
                type: sortType
            },
            perPage,
            accessToken
        })
        e.preventDefault()
    }

    const changePerPage = (val) => {
        setPerPage(val)
        actionFetchUjianPeserta({
            search,
            page,
            sort,
            perPage: val,
            accessToken
        })
    }

    useEffect(() => {
        let queryParams = {}
        if (page) {
            queryParams['page'] = page
        }
        if (search) {
            queryParams['search'] = search
        }
        if (sort?.by) {
            queryParams['sortBy'] = sort?.by
        }
        if (sort?.type) {
            queryParams['sortType'] = sort?.type
        }
        if (perPage) {
            queryParams['perPage'] = perPage
        }

        let query = new URLSearchParams(queryParams).toString()

        changeUrl("ujian-peserta?" + query)
    }, [page, search, sort?.by, sort?.type, perPage])

    const pageData = (val, e) => {
        setPage(val)
        actionFetchUjianPeserta({
            search,
            page: val,
            sort,
            perPage,
            accessToken
        })
        e.preventDefault()
    }

    const onSearch = (e) => {
        setSearch(searchValue)
        actionFetchUjianPeserta({
            search: searchValue,
            page,
            sort,
            perPage,
            accessToken
        })
        e.preventDefault()
    }

    const onChangeSearch = (val) => {
        setSearchValue(val)
    }

    useEffect(() => {
        if (page && index?.last_page) {
            if (page > index?.last_page) {
                setPage(index?.last_page)
                actionFetchUjianPeserta({
                    search,
                    page: index?.last_page,
                    sort,
                    perPage,
                    accessToken
                })
            } else if (page < 0) {
                setPage(1)
                actionFetchUjianPeserta({
                    search,
                    page: 1,
                    sort,
                    perPage,
                    accessToken
                })
            }
        }
    }, [page, index?.last_page])

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col-lg-8"></div>
                <div className="col-lg-4">
                    <SearchTable onSearch={onSearch} onChange={onChangeSearch} value={searchValue} />
                </div>
            </div>

            {
                index?.total > 0 ?
                    <p className="mt-2">Menampilkan {index?.from} - {index?.to} dari {index?.total} data</p>
                    :
                    <p className="mt-2">Menampilkan 0 data</p>
            }

            <div className="card mt-3">
                <div className="table-responsive grid-view">
                    <table className="table">
                        <HeadTable
                            sort={sort}
                            sortData={sortData}
                            columns={[
                                { type: "label", label: "No." },
                                { type: "sortable", label: "Nama", field: "name" },
                                { type: "sortable", label: "NIK", field: "nik" },
                                { type: "sortable", label: "Nilai Akhir", field: "nilai_akhir" },
                                { type: "sortable", label: "Waktu Ujian", field: "waktu_ujian" },
                                { type: "label", label: "" }
                            ]}
                        />
                        <tbody className="table-border-bottom-0">
                            {
                                index?.list?.length > 0 ?
                                    index?.list?.map((val, key) => {
                                        return <tr key={key}>
                                            <td><strong>{((page - 1) * perPage) + key + 1}</strong></td>
                                            <td>{val?.user?.name}</td>
                                            <td>{val?.peserta?.nik}</td>
                                            <td>{val?.nilai_akhir}</td>
                                            <td>{asTimeWithLabel(val?.waktu_ujian)}</td>
                                            <td className="text-end">
                                                <Link to={{
                                                    pathname: process.env.REACT_APP_SUBDIR + "/ujian-peserta/detail/" + val?.id,
                                                    state: val
                                                }} className="btn btn-info btn-sm">Detail</Link>
                                            </td>
                                        </tr>
                                    })
                                    : <tr>
                                        <td colSpan={7}>Data tidak ditemukan</td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-lg-8">
                    <Pagination page={page} pageUrl={index?.pageUrl} onChange={pageData} last_page={index?.last_page} />
                </div>
                <div className="col-lg-4">
                    <LimitTable perPage={perPage} onChange={changePerPage} />
                </div>
            </div>
        </div>
    </React.Fragment >
}

const mapStateToProps = state => {
    const { auth: { accessToken }, ujianPeserta: { index } } = state

    return { accessToken, index }
}

export default withRouter(connect(mapStateToProps, { actionFetchUjianPeserta })(UjianPesertaIndex))