import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { actionCreatePesertaUjian, actionResetFormPesertaUjian } from "../../store/peserta-ujian/actions"
import { alert } from "../../utils/alert"
import { pushUrl } from "../../utils/url"
import NumberInput from "../../components/Form/NumberInput"
import TextInput from "../../components/Form/TextInput"

const PesertaUjianCreate = (props) => {
    const { actionCreatePesertaUjian, actionResetFormPesertaUjian, accessToken, form } = props

    const [payload, setPayload] = useState({
        nama: "",
        nik: "",
        nomor_hp: "",
        email: ""
    })
    const [errors, setErrors] = useState({})
    const [submit, setSubmit] = useState(false)

    useEffect(() => {
        const titlePage = "Tambah Hasil Seleksi"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)

        actionResetFormPesertaUjian()

        return () => actionResetFormPesertaUjian()
    }, [])

    useEffect(async () => {
        if (submit === true) {
            if (form?.success === true) {
                await alert({ title: "Berhasil", html: form?.message, icon: "success" })
                pushUrl("/peserta-ujian")
            } else if (form?.success === false) {
                await alert({ title: "Gagal", html: form?.message, icon: "error" })
            }
        }
    }, [form?.success, submit])

    const onChange = ((field, value) => {
        if (field === "nomor_hp") {
            payload[field] = value?.slice(0, 5)
        } else {
            payload[field] = value
        }

        setPayload({
            ...payload,
        })
    })

    const onSubmit = (e) => {
        if (validate() === true) {
            setSubmit(true)

            actionCreatePesertaUjian({
                payload,
                accessToken
            })
        }

        e.preventDefault()
    }

    const validate = () => {
        let jsonErrors = {}
        let hasError = false

        if (!payload?.nama || payload?.nama === "") {
            hasError = true
            jsonErrors["nama"] = "Nama harus diisi"
        }
        if (!payload?.nik || payload?.nik === "") {
            hasError = true
            jsonErrors["nik"] = "NIK Maks harus diisi"
        }
        if (!payload?.nomor_hp || payload?.nomor_hp === "") {
            hasError = true
            jsonErrors["nomor_hp"] = "Nomor HP harus diisi"
        } else {
            if (payload?.nomor_hp?.length > 5) {
                hasError = true
                jsonErrors["nomor_hp"] = "Maksimal Nomor HP 5 digit"
            }
        }
        if (!payload?.email || payload?.email === "") {
            hasError = true
            jsonErrors["email"] = "Email harus diisi"
        }

        setErrors(jsonErrors)

        if (hasError === true) {
            return false
        } else {
            return true
        }
    }

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <Link to={process.env.REACT_APP_SUBDIR + "/peserta-ujian"} className="btn btn-white mb-2 btn-md-block">Kembali</Link>

            <div className="card mt-3">
                <div className="card-body">
                    <form onSubmit={onSubmit} >
                        <div className="row">
                            <div className="col-lg-12 col-xl-10 col-xxl-8">
                                <TextInput
                                    id={"nama"}
                                    name={"nama"}
                                    placeholder={"Masukkan nama peserta"}
                                    label={"Nama"}
                                    onChange={(e) => onChange('nama', e?.target?.value)}
                                    required={true}
                                    value={payload?.nama}
                                    error={errors?.nama}
                                    errors={form?.errors?.nama}
                                    tabIndex={1} />

                                <NumberInput
                                    id={"nik"}
                                    name={"nik"}
                                    placeholder={"Masukkan NIK"}
                                    label={"NIK"}
                                    onChange={(e) => onChange('nik', e?.target?.value)}
                                    required={true}
                                    value={payload?.nik}
                                    error={errors?.nik}
                                    errors={form?.errors?.nik}
                                    tabIndex={2} />

                                <NumberInput
                                    id={"nomor_hp"}
                                    name={"nomor_hp"}
                                    placeholder={"Masukkan nomor hp"}
                                    label={"Nomor HP"}
                                    onChange={(e) => onChange('nomor_hp', e?.target?.value)}
                                    required={true}
                                    value={payload?.nomor_hp}
                                    error={errors?.nomor_hp}
                                    errors={form?.errors?.nomor_hp}
                                    tabIndex={3} />

                                <TextInput
                                    id={"email"}
                                    name={"email"}
                                    placeholder={"Masukkan email"}
                                    label={"Email"}
                                    onChange={(e) => onChange('email', e?.target?.value)}
                                    required={true}
                                    value={payload?.email}
                                    error={errors?.email}
                                    errors={form?.errors?.email}
                                    tabIndex={4} />

                                <button
                                    tabIndex={5}
                                    type="submit" className="btn btn-primary btn-md-block" disabled={form?.loading === true}>
                                    Kirim
                                    {form?.loading === true ? <span className="spinner-border ms-2" role="nomor_hp" aria-hidden="true"></span> : null}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </React.Fragment>
}

const mapStateToProps = state => {
    const { auth: { accessToken }, pesertaUjian: { form } } = state

    return { accessToken, form }
}

export default withRouter(connect(mapStateToProps, { actionCreatePesertaUjian, actionResetFormPesertaUjian })(PesertaUjianCreate))