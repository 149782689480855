import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import HeadTable from "../../components/Table/HeadTable"
import { alert } from "../../utils/alert"
import { asTimeWithLabel } from "../../utils/formatter"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { request } from "../../utils/request"

const UjianPesertaDetail = (props) => {
    const { accessToken, match: { params: { id } }, history: { location: { state } } } = props
    const [data, setData] = useState(null)
    const [noData, setNoData] = useState(false)

    useEffect(() => {
        const titlePage = "Detail Ujian Peserta"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)

        if (state) {
            setData(state)
        }
        getData()
    }, [])

    const getData = async () => {
        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }
            const res = await request(process.env.REACT_APP_SERVICE_UJIAN_PESERTA + '/' + id, {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                setData(res?.result)
            } else {
                setNoData(true)
            }
        } catch (error) {
            setNoData(true)
            alert({ title: "Gagal", html: "Gagal meminta data ke server" })
        }
    }

    const classStatus = (val) => {
        if (val?.toString() === "1") {
            return "bg-label-primary"
        } else if (val?.toString() === "0") {
            return "bg-label-danger"
        }

        return "bg-label-secondary"
    }

    const valStatus = (val) => {
        if (val?.toString() === "1") {
            return "Benar"
        } else if (val?.toString() === "0") {
            return "Salah"
        }

        return ""
    }

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <Link to={process.env.REACT_APP_SUBDIR + "/ujian-peserta"} className="btn btn-white mb-2 btn-md-block">Kembali</Link>

            <div className="card mt-3">
                <div className="card-body">
                    {
                        noData === true ?
                            <span>Data tidak ditemukan</span>
                            :
                            <React.Fragment>
                                <small className="text-muted text-uppercase">Informasi Peserta</small>
                                <div className="table-responsive detail-view mt-2">
                                    <table className="table table-bordered">
                                        <tbody className="table-border-bottom-0">
                                            <tr>
                                                <th style={{ width: "25%" }}>
                                                    <strong>Nama</strong>
                                                </th>
                                                <td>{data?.user?.name}</td>
                                            </tr>
                                            <tr>
                                                <th style={{ width: "25%" }}>
                                                    <strong>NIK</strong>
                                                </th>
                                                <td>{data?.peserta?.nik}</td>
                                            </tr>
                                            <tr>
                                                <th style={{ width: "25%" }}>
                                                    <strong>Email</strong>
                                                </th>
                                                <td>{data?.user?.email}</td>
                                            </tr>
                                            <tr>
                                                <th style={{ width: "25%" }}>
                                                    <strong>Status</strong>
                                                </th>
                                                <td>{data?.status}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </React.Fragment>
                    }
                </div>
            </div>
            {
                noData !== true ?
                    <div className="card mt-3">
                        <div className="card-body">
                            <small className="text-muted text-uppercase">Hasil Ujian</small>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="table-responsive detail-view mt-2">
                                        <table className="table table-bordered">
                                            <tbody className="table-border-bottom-0">
                                                <tr>
                                                    <th style={{ width: "152px" }}>
                                                        <strong>Waktu Mulai</strong>
                                                    </th>
                                                    <td>{data?.waktu_mulai || "-"}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "152px" }}>
                                                        <strong>Waktu Selesai</strong>
                                                    </th>
                                                    <td>{data?.waktu_selesai || "-"}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "152px" }}>
                                                        <strong>Waktu Ujian</strong>
                                                    </th>
                                                    <td>{asTimeWithLabel(data?.waktu_ujian) || "-"}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "152px" }}>
                                                        <strong>Nilai Akhir</strong>
                                                    </th>
                                                    <td>{data?.nilai_akhir || 0}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="table-responsive detail-view mt-2">
                                        <table className="table table-bordered">
                                            <tbody className="table-border-bottom-0">
                                                <tr>
                                                    <th>
                                                        <strong>Jumlah Dikerjakan</strong>
                                                    </th>
                                                    <td>{data?.jumlah_dikerjakan || 0}</td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <strong>Jumlah Tidak Dikerjakan</strong>
                                                    </th>
                                                    <td>{data?.jumlah_tidak_dikerjakan || 0}</td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <strong>Jumlah Benar</strong>
                                                    </th>
                                                    <td>{data?.jumlah_benar || 0}</td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <strong>Jumlah Salah</strong>
                                                    </th>
                                                    <td>{data?.jumlah_salah || 0}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-4">
                                <div className="table-responsive detail-view">
                                    <table className="table table-bordered">
                                        <HeadTable
                                            colorized={false}
                                            columns={[
                                                { type: "label", label: "No." },
                                                { type: "label", label: "Pertanyaan & Jawaban" },
                                                { type: "label", label: "Kategori" },
                                                { type: "label", label: "Hasil" }
                                            ]} />
                                        <tbody className="table-border-bottom-0">
                                            {
                                                data?.jawaban?.length > 0 ?
                                                    data?.jawaban?.map((val, key) => {
                                                        return <React.Fragment key={key}>
                                                            <tr>
                                                                <td rowSpan={2}>{key + 1}</td>
                                                                <td>
                                                                    <span dangerouslySetInnerHTML={{ __html: val?.soal?.pertanyaan }}></span>
                                                                </td>
                                                                <td style={{ width: "200px" }} rowSpan={2}>{val?.soal?.kategori_soal?.nama || "-"}</td>
                                                                <td style={{ width: "100px" }} rowSpan={2}><span className={"badge me-1 " + classStatus(val?.jawaban_betul)}>{valStatus(val?.jawaban_betul) || "-"}</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td>{val?.jawaban?.kode ? "[" + val?.jawaban?.kode + "]" : ""} {val?.jawaban?.jawaban || "-"}</td>
                                                            </tr>
                                                        </React.Fragment>
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={3}>Tidak ada data</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </div>
    </React.Fragment>
}

const mapStateToProps = state => {
    const { auth: { accessToken } } = state

    return { accessToken }
}

export default withRouter(connect(mapStateToProps, {})(UjianPesertaDetail))