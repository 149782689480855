import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { actionDeletePesertaUjian, actionResetDeletePesertaUjian } from "../../store/peserta-ujian/actions"
import { request } from "../../utils/request"
import { alert, confirm } from "../../utils/alert"
import { pushUrl } from "../../utils/url"
import HeadTable from "../../components/Table/HeadTable"

const PesertaUjianDetail = (props) => {
    const { actionDeletePesertaUjian, actionResetDeletePesertaUjian, accessToken, match: { params: { id } }, history: { location: { state } }, del } = props
    const [data, setData] = useState(null)
    const [noData, setNoData] = useState(false)
    const [submit, setSubmit] = useState(false)

    useEffect(() => {
        const titlePage = "Detail Hasil Seleksi"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)

        if (state) {
            setData(state)
        } else {
            getData()
        }

        actionResetDeletePesertaUjian()

        return () => actionResetDeletePesertaUjian()
    }, [])

    useEffect(() => {
        if (submit === true) {
            if (del?.success === true) {
                alert({ title: "Berhasil hapus data" })
                pushUrl("/peserta-ujian")
            } else if (del?.success === false) {
                alert({ title: "Gagal hapus data" })
            }
        }
    }, [del?.success, submit])

    const getData = async () => {
        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }
            const res = await request(process.env.REACT_APP_SERVICE_PESERTA_UJIAN + '/' + id, {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                setData(res?.result)
            } else {
                setNoData(true)
            }
        } catch (error) {
            setNoData(true)
            alert({ title: "Gagal", html: "Gagal meminta data ke server" })
        }
    }

    const deleteData = async (e) => {
        e.preventDefault()

        let res = await confirm({
            title: "Hapus pertanyaan",
            html: "Apakah anda yakin menghapus data ini?"
        })
        if (res === true) {
            setSubmit(true)
            actionDeletePesertaUjian({ accessToken, id })
        }
    }

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col-lg-4">
                    <Link to={process.env.REACT_APP_SUBDIR + "/peserta-ujian"} className="btn btn-white mb-2 btn-md-block">Kembali</Link>
                </div>
                <div className="col-lg-8 text-end">
                    <Link to={process.env.REACT_APP_SUBDIR + "/peserta-ujian/update/" + data?.id + "?from=detail"} className="btn btn-success mb-2 btn-md-block">Ubah</Link>
                    <a href="/#" onClick={(e) => deleteData(e)} className={"btn btn-danger mb-2 btn-md-block " + (del?.loading === true ? "disabled" : "")}>Hapus {del?.loading === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}</a>
                </div>
            </div>

            <div className="card mt-3">
                <div className="card-body">
                    {
                        noData === true ?
                            <span>Data tidak ditemukan</span>
                            :
                            <div className="table-responsive text-nowrap detail-view">
                                <table className="table table-bordered">
                                    <tbody className="table-border-bottom-0">
                                        <tr>
                                            <th style={{ width: "25%" }}>
                                                <strong>Nama</strong>
                                            </th>
                                            <td>{data?.nama}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ width: "25%" }}>
                                                <strong>NIK</strong>
                                            </th>
                                            <td>{data?.nik}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ width: "25%" }}>
                                                <strong>Nomor HP</strong>
                                            </th>
                                            <td>{data?.nomor_hp}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ width: "25%" }}>
                                                <strong>Email</strong>
                                            </th>
                                            <td>{data?.email}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ width: "25%" }}>
                                                <strong>Status</strong>
                                            </th>
                                            <td>{data?.status}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                    }
                </div>
            </div>
        </div>
    </React.Fragment>
}

const mapStateToProps = state => {
    const { auth: { accessToken }, pesertaUjian: { del } } = state

    return { accessToken, del }
}

export default withRouter(connect(mapStateToProps, { actionDeletePesertaUjian, actionResetDeletePesertaUjian })(PesertaUjianDetail))