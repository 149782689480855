import actionTypes from './actionTypes'

export const actionFetchPesertaUjian = (data) => {
  return {
    type: actionTypes.FETCH_PESERTA_UJIAN,
    data
  }
}

export const actionFetchPesertaUjianStart = () => {
  return {
    type: actionTypes.FETCH_PESERTA_UJIAN_START
  }
}

export const actionFetchPesertaUjianEnd = () => {
  return {
    type: actionTypes.FETCH_PESERTA_UJIAN_END
  }
}

export const actionFetchPesertaUjianSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PESERTA_UJIAN_SUCCESS,
    data
  }
}

export const actionCreatePesertaUjian = (data) => {
  return {
    type: actionTypes.CREATE_PESERTA_UJIAN,
    data
  }
}

export const actionCreatePesertaUjianStart = () => {
  return {
    type: actionTypes.CREATE_PESERTA_UJIAN_START
  }
}

export const actionCreatePesertaUjianEnd = () => {
  return {
    type: actionTypes.CREATE_PESERTA_UJIAN_END
  }
}

export const actionCreatePesertaUjianSuccess = (data) => {
  return {
    type: actionTypes.CREATE_PESERTA_UJIAN_SUCCESS,
    data
  }
}

export const actionCreatePesertaUjianFailed = (data) => {
  return {
    type: actionTypes.CREATE_PESERTA_UJIAN_FAILED,
    data
  }
}

export const actionUpdatePesertaUjian = (data) => {
  return {
    type: actionTypes.UPDATE_PESERTA_UJIAN,
    data
  }
}

export const actionUpdatePesertaUjianStart = () => {
  return {
    type: actionTypes.UPDATE_PESERTA_UJIAN_START
  }
}

export const actionUpdatePesertaUjianEnd = () => {
  return {
    type: actionTypes.UPDATE_PESERTA_UJIAN_END
  }
}

export const actionUpdatePesertaUjianSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_PESERTA_UJIAN_SUCCESS,
    data
  }
}

export const actionUpdatePesertaUjianFailed = (data) => {
  return {
    type: actionTypes.UPDATE_PESERTA_UJIAN_FAILED,
    data
  }
}

export const actionResetFormPesertaUjian = () => {
  return {
    type: actionTypes.RESET_FORM_PESERTA_UJIAN
  }
}

export const actionDeletePesertaUjian = (data) => {
  return {
    type: actionTypes.DELETE_PESERTA_UJIAN,
    data
  }
}

export const actionDeletePesertaUjianStart = () => {
  return {
    type: actionTypes.DELETE_PESERTA_UJIAN_START
  }
}

export const actionDeletePesertaUjianEnd = () => {
  return {
    type: actionTypes.DELETE_PESERTA_UJIAN_END
  }
}

export const actionDeletePesertaUjianSuccess = () => {
  return {
    type: actionTypes.DELETE_PESERTA_UJIAN_SUCCESS
  }
}

export const actionDeletePesertaUjianFailed = () => {
  return {
    type: actionTypes.DELETE_PESERTA_UJIAN_FAILED
  }
}

export const actionResetDeletePesertaUjian = () => {
  return {
    type: actionTypes.RESET_DELETE_PESERTA_UJIAN
  }
}

export const actionImportPesertaUjian = (data) => {
  return {
    type: actionTypes.IMPORT_PESERTA_UJIAN,
    data
  }
}

export const actionImportPesertaUjianStart = () => {
  return {
    type: actionTypes.IMPORT_PESERTA_UJIAN_START
  }
}

export const actionImportPesertaUjianEnd = () => {
  return {
    type: actionTypes.IMPORT_PESERTA_UJIAN_END
  }
}

export const actionImportPesertaUjianSuccess = (data) => {
  return {
    type: actionTypes.IMPORT_PESERTA_UJIAN_SUCCESS,
    data
  }
}

export const actionImportPesertaUjianFailed = (data) => {
  return {
    type: actionTypes.IMPORT_PESERTA_UJIAN_FAILED,
    data
  }
}