import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { actionUpdateSesiUjian, actionResetFormSesiUjian } from "../../store/sesi-ujian/actions"
import TextInput from "../../components/Form/TextInput"
import { alert } from "../../utils/alert"
import { pushUrl } from "../../utils/url"
import Dropdown from "../../components/Form/Dropdown"
import DatePicker from "../../components/Form/DatePicker"
import { request } from "../../utils/request"
import { dateTime, strtotime } from "../../utils/formatter"
import NumberInput from "../../components/Form/NumberInput"

let optJamDefault = []
for (let i = 0; i < 24; i++) {
    optJamDefault.push({
        label: (i < 10 ? "0" : "") + i.toString(),
        value: (i < 10 ? "0" : "") + i.toString()
    })
}

let optMenit = []
for (let i = 0; i < 60; i++) {
    optMenit.push({
        label: (i < 10 ? "0" : "") + i.toString(),
        value: (i < 10 ? "0" : "") + i.toString()
    })
}

const SesiUjianUpdate = (props) => {
    const { actionUpdateSesiUjian, actionResetFormSesiUjian, accessToken, match: { params: { id } }, history: { location: { state } }, form } = props

    const [payload, setPayload] = useState({
        nama: "",
        tanggal_ujian: null,
        jam_mulai: "",
        jam_selesai: "",
        menit_mulai: "",
        menit_selesai: "",
        batas_peserta: "",
        status: ""
    })
    const [errors, setErrors] = useState({})
    const [submit, setSubmit] = useState(false)
    const [waktuTerisi, setWaktuTerisi] = useState(null)
    const [jamTerisi, setJamTerisi] = useState([])
    const [optJamMulai, setOptJamMulai] = useState(optJamDefault)
    const [optJamSelesai, setOptJamSelesai] = useState(optJamDefault)
    const [noData, setNoData] = useState(false)
    const [from, setFrom] = useState("/sesi-ujian")

    useEffect(() => {
        const titlePage = "Ubah Sesi Ujian"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)

        if (state) {
            formatPayload(state)
        } else {
            getData()
        }

        const query = new URLSearchParams(props.location.search)
        if (query.get('from') === "detail") {
            setFrom("/sesi-ujian/detail/" + id)
        }

        actionResetFormSesiUjian()

        return () => actionResetFormSesiUjian()
    }, [])

    const formatPayload = (data) => {
        let tanggal_ujian = new Date(strtotime(data?.waktu_mulai))
        let jam_mulai = dateTime(strtotime(data?.waktu_mulai), "HH")
        let jam_selesai = dateTime(strtotime(data?.waktu_selesai), "HH")
        let menit_mulai = dateTime(strtotime(data?.waktu_mulai), "mm")
        let menit_selesai = dateTime(strtotime(data?.waktu_selesai), "mm")

        setPayload({
            nama: data?.nama,
            tanggal_ujian,
            jam_mulai,
            jam_selesai,
            menit_mulai,
            menit_selesai,
            batas_peserta: data?.batas_peserta,
            status: data?.status
        })
    }

    const getData = async () => {
        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }
            const res = await request(process.env.REACT_APP_SERVICE_SESI_UJIAN + '/' + id, {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                formatPayload(res?.result)
            } else {
                setNoData(true)
            }
        } catch (error) {
            setNoData(true)
            alert({ title: "Gagal", html: "Gagal meminta data ke server" })
        }
    }

    useEffect(async () => {
        if (submit === true) {
            if (form?.success === true) {
                await alert({ title: "Berhasil", html: form?.message, icon: "success" })
                pushUrl(from)
            } else if (form?.success === false) {
                await alert({ title: "Gagal", html: form?.message, icon: "error" })
            }
        }
    }, [form?.success, submit])

    const onChange = ((field, value) => {
        payload[field] = value

        setPayload({
            ...payload,
        })
    })

    const onSubmit = (e) => {
        if (validate() === true) {
            setSubmit(true)

            let _payload = {
                ...payload,
                tanggal_ujian: dateTime(strtotime(payload?.tanggal_ujian), 'YYYY-MM-DD')
            }

            actionUpdateSesiUjian({
                id,
                payload: _payload,
                accessToken
            })
        }

        e.preventDefault()
    }

    const validate = () => {
        let jsonErrors = {}
        let hasError = false

        if (!payload?.nama || payload?.nama === "") {
            hasError = true
            jsonErrors["nama"] = "Nama harus diisi"
        }
        if (!payload?.tanggal_ujian || payload?.tanggal_ujian === "") {
            hasError = true
            jsonErrors["tanggal_ujian"] = "Tanggal ujian harus diisi"
        }
        if (!payload?.jam_mulai || payload?.jam_mulai === "") {
            hasError = true
            jsonErrors["jam_mulai"] = "Jam mulai harus diisi"
        }
        if (!payload?.jam_selesai || payload?.jam_selesai === "") {
            hasError = true
            jsonErrors["jam_selesai"] = "Jam selesai harus diisi"
        }
        if (!payload?.menit_mulai || payload?.menit_mulai === "") {
            hasError = true
            jsonErrors["menit_mulai"] = "Menit mulai harus diisi"
        }
        if (!payload?.menit_selesai || payload?.menit_selesai === "") {
            hasError = true
            jsonErrors["menit_selesai"] = "Menit selesai harus diisi"
        }
        if (!payload?.batas_peserta || payload?.batas_peserta === "") {
            hasError = true
            jsonErrors["batas_peserta"] = "Batas peserta harus diisi"
        }
        if (!payload?.status || payload?.status === "") {
            hasError = true
            jsonErrors["status"] = "Status harus diisi"
        }

        setErrors(jsonErrors)

        if (hasError === true) {
            return false
        } else {
            return true
        }
    }

    useEffect(async () => {
        if (payload?.tanggal_ujian) {
            var tanggal_ujian = dateTime(strtotime(payload?.tanggal_ujian), 'YYYY-MM-DD')
            try {
                const header = {
                    "Authorization": `Bearer ${accessToken}`,
                }
                const res = await request(process.env.REACT_APP_SERVICE_SESI_UJIAN + '/cek-tanggal-ujian?tanggal=' + tanggal_ujian + '&id=' + id, {
                    method: 'GET',
                    headers: header
                })

                if (res?.success === true) {
                    setWaktuTerisi(res?.result?.waktu_terisi)
                    setOptJamMulai(res?.result?.jam_tersedia)
                    setJamTerisi(res?.result?.jam_terisi)
                    setOptJamSelesai(optJamDefault)
                } else {
                    setWaktuTerisi(null)
                }
            } catch (error) {
                setWaktuTerisi(null)
                alert({ title: "Gagal", html: "Gagal meminta data ke server" })
            }
        }
    }, [payload?.tanggal_ujian])

    useEffect(async () => {
        if (payload?.jam_mulai && jamTerisi) {
            let arrJamSelesai = []
            let jamMaks = 24
            if (jamTerisi?.length > 0 && payload?.jam_mulai < jamTerisi[jamTerisi?.length - 1]) {
                jamMaks = jamTerisi[jamTerisi?.length - 1]
            }
            for (let i = parseInt(payload?.jam_mulai) + 1; i < jamMaks; i++) {
                if (!jamTerisi.includes(i)) {
                    arrJamSelesai.push({
                        label: (i < 10 ? "0" : "") + i.toString(),
                        value: (i < 10 ? "0" : "") + i.toString()
                    })
                } else if (jamTerisi.includes(i)) {
                    break
                }
            }
            setOptJamSelesai(arrJamSelesai)
        }
    }, [payload?.jam_mulai, jamTerisi])

    const classTimeline = (key) => {
        const t = key % 2

        if (t === 0) {
            return "primary"
        } else if (t === 1) {
            return "secondary"
        }
    }

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <Link to={process.env.REACT_APP_SUBDIR + from} className="btn btn-white mb-2 btn-md-block">Kembali</Link>

            <div className="row">
                <div className="col-lg-6">
                    <div className="card mt-3">
                        <div className="card-body">
                            {
                                noData === true ?
                                    <span>Data tidak ditemukan</span>
                                    :
                                    <form onSubmit={onSubmit} >
                                        <DatePicker
                                            id={"tanggal_ujian"}
                                            name={"tanggal_ujian"}
                                            placeholder={"Masukkan tanggal ujian"}
                                            label={"Tanggal Ujian"}
                                            onChange={(val) => onChange('tanggal_ujian', val)}
                                            required={true}
                                            value={payload?.tanggal_ujian}
                                            error={errors?.tanggal_ujian}
                                            errors={form?.errors?.tanggal_ujian}
                                            tabIndex={1} />

                                        <TextInput
                                            id={"nama"}
                                            name={"nama"}
                                            placeholder={"Masukkan nama sesi"}
                                            label={"Nama"}
                                            onChange={(e) => onChange('nama', e?.target?.value)}
                                            required={true}
                                            value={payload?.nama}
                                            error={errors?.nama}
                                            errors={form?.errors?.nama}
                                            tabIndex={2} />


                                        <div className="row">
                                            <div className="col-md-6">
                                                <Dropdown
                                                    id={"jam_mulai"}
                                                    name={"jam_mulai"}
                                                    placeholder={"Pilih jam mulai"}
                                                    label={"Jam Mulai"}
                                                    onChange={(e) => onChange('jam_mulai', e?.target?.value)}
                                                    required={true}
                                                    value={payload?.jam_mulai}
                                                    error={errors?.jam_mulai}
                                                    errors={form?.errors?.jam_mulai}
                                                    tabIndex={3}
                                                    options={optJamMulai} />
                                            </div>
                                            <div className="col-md-6">
                                                <Dropdown
                                                    id={"menit_mulai"}
                                                    name={"menit_mulai"}
                                                    placeholder={"Pilih menit mulai"}
                                                    label={"Menit Mulai"}
                                                    onChange={(e) => onChange('menit_mulai', e?.target?.value)}
                                                    required={true}
                                                    value={payload?.menit_mulai}
                                                    error={errors?.menit_mulai}
                                                    errors={form?.errors?.menit_mulai}
                                                    tabIndex={4}
                                                    options={optMenit} />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <Dropdown
                                                    id={"jam_selesai"}
                                                    name={"jam_selesai"}
                                                    placeholder={"Pilih jam selesai"}
                                                    label={"Jam Selesai"}
                                                    onChange={(e) => onChange('jam_selesai', e?.target?.value)}
                                                    required={true}
                                                    value={payload?.jam_selesai}
                                                    error={errors?.jam_selesai}
                                                    errors={form?.errors?.jam_selesai}
                                                    tabIndex={5}
                                                    options={optJamSelesai} />
                                            </div>

                                            <div className="col-md-6">
                                                <Dropdown
                                                    id={"menit_selesai"}
                                                    name={"menit_selesai"}
                                                    placeholder={"Pilih menit selesai"}
                                                    label={"Menit Selesai"}
                                                    onChange={(e) => onChange('menit_selesai', e?.target?.value)}
                                                    required={true}
                                                    value={payload?.menit_selesai}
                                                    error={errors?.menit_selesai}
                                                    errors={form?.errors?.menit_selesai}
                                                    tabIndex={6}
                                                    options={optMenit} />
                                            </div>
                                        </div>

                                        <NumberInput
                                            id={"batas_peserta"}
                                            name={"batas_peserta"}
                                            placeholder={"Masukkan batas peserta"}
                                            label={"Batas Peserta"}
                                            onChange={(e) => onChange('batas_peserta', e?.target?.value)}
                                            required={true}
                                            value={payload?.batas_peserta}
                                            error={errors?.batas_peserta}
                                            errors={form?.errors?.batas_peserta}
                                            tabIndex={7} />

                                        <Dropdown
                                            id={"status"}
                                            name={"status"}
                                            placeholder={"Pilih status"}
                                            label={"Status"}
                                            onChange={(e) => onChange('status', e?.target?.value)}
                                            required={true}
                                            value={payload?.status}
                                            error={errors?.status}
                                            errors={form?.errors?.status}
                                            tabIndex={8}
                                            options={[
                                                { label: "Aktif", value: "Aktif" },
                                                { label: "Tidak Aktif", value: "Tidak Aktif" }
                                            ]} />

                                        <button
                                            tabIndex={9}
                                            type="submit" className="btn btn-primary btn-md-block" disabled={form?.loading === true}>
                                            Kirim
                                            {form?.loading === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}
                                        </button>
                                    </form>
                            }
                        </div>
                    </div>
                </div>
                {
                    waktuTerisi?.length > 0 ?
                        <div className="col-lg-6">
                            <ul className="timeline timeline-dashed mt-4 h-auto">
                                {
                                    waktuTerisi?.map((val, key) => {
                                        return <li key={key} className="timeline-item mb-4">
                                            <span className="timeline-indicator timeline-indicator-default">
                                                <i className="bx bx-time"></i>
                                            </span>
                                            <div className="timeline-event">
                                                <div className="timeline-header border-bottom mb-3">
                                                    <h6 className="mb-0">{val?.nama}</h6>
                                                    <small className="text-muted">{dateTime(strtotime(val?.tanggal_ujian, 'YYYY-MM-DD'), 'DD-MM-YYYY')}</small>
                                                </div>
                                                <div className="d-flex justify-content-between flex-wrap mb-2">
                                                    <div>
                                                        <span>{val?.waktu_mulai}</span>
                                                        <i className="bx bx-right-arrow-alt scaleX-n1-rtl mx-3"></i>
                                                        <span>{val?.waktu_selesai}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                        : null
                }
            </div>
        </div>
    </React.Fragment>
}

const mapStateToProps = state => {
    const { auth: { accessToken }, sesiUjian: { form } } = state

    return { accessToken, form }
}

export default withRouter(connect(mapStateToProps, { actionUpdateSesiUjian, actionResetFormSesiUjian })(SesiUjianUpdate))