import Immutable from 'seamless-immutable'

const FETCH_LAPORAN_PESERTA = 'FETCH_LAPORAN_PESERTA'
const FETCH_LAPORAN_PESERTA_START = 'FETCH_LAPORAN_PESERTA_START'
const FETCH_LAPORAN_PESERTA_END = 'FETCH_LAPORAN_PESERTA_END'
const FETCH_LAPORAN_PESERTA_SUCCESS = 'FETCH_LAPORAN_PESERTA_SUCCESS'
const FETCH_LAPORAN_PESERTA_FAILED = 'FETCH_LAPORAN_PESERTA_FAILED'

const actionTypes = Immutable({
  FETCH_LAPORAN_PESERTA,
  FETCH_LAPORAN_PESERTA_START,
  FETCH_LAPORAN_PESERTA_END,
  FETCH_LAPORAN_PESERTA_SUCCESS,
  FETCH_LAPORAN_PESERTA_FAILED
})

export default actionTypes
