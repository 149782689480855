import { takeEvery, put, all, call, select } from 'redux-saga/effects'
import actionTypes from './actionTypes'
import { request } from '../../utils/request'
import { alert } from '../../utils/alert'
import { actionUpdateSoalUjianEnd, actionUpdateSoalUjianFailed, actionUpdateSoalUjianStart, actionUpdateSoalUjianSuccess, actionCreateSoalUjianEnd, actionCreateSoalUjianFailed, actionCreateSoalUjianStart, actionCreateSoalUjianSuccess, actionFetchSoalUjianEnd, actionFetchSoalUjianStart, actionFetchSoalUjianSuccess, actionDeleteSoalUjianSuccess, actionDeleteSoalUjianFailed, actionDeleteSoalUjianStart, actionDeleteSoalUjianEnd, actionImportSoalUjianEnd, actionImportSoalUjianFailed, actionImportSoalUjianSuccess, actionImportSoalUjianStart } from './actions'
import { pushUrl } from '../../utils/url'

function* sagaFetchSoalUjian(params) {
  yield put(actionFetchSoalUjianStart())

  const { data: { accessToken, search, page, sort, perPage } } = params
  const header = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let sortParam = ""
    if (sort?.by) {
      sortParam += "&sortBy=" + sort?.by
    }
    if (sort?.type) {
      sortParam += "&sortType=" + sort?.type
    }

    let perPageParam = perPage ? "&perPage=" + perPage : ""

    let pageParam = page ? "?page=" + page : "?page=1"

    let searchParam = search ? "&search=" + search : ""

    let url = `${process.env.REACT_APP_SERVICE_SOAL_UJIAN}${pageParam}${searchParam}${sortParam}${perPageParam}`
    const res = yield call(request, url, {
      method: 'GET',
      headers: header
    })

    if (res?.success === true) {
      let data = {
        list: res?.result?.data,
        page: res?.result?.current_page,
        pageUrl: {
          first_page_url: res?.result?.first_page_url,
          links: res?.result?.links,
          last_page_url: res?.result?.last_page_url
        },
        last_page: res?.result?.last_page,
        from: res?.result?.from,
        to: res?.result?.to,
        total: res?.result?.total
      }
      yield put(actionFetchSoalUjianSuccess(data))
    } else {
      alert({ title: "Gagal", html: res?.message })
    }
  } catch (err) {
    alert({ title: "Gagal" })
    // yield put(actionSignInFailed())
  }

  yield put(actionFetchSoalUjianEnd())
}

function* sagaCreateSoalUjian(params) {
  yield put(actionCreateSoalUjianStart())

  const { data: { accessToken, payload } } = params
  const headerPost = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_SOAL_UJIAN}`
    const res = yield call(request, url, {
      method: 'POST',
      headers: headerPost,
      body: JSON.stringify(payload)
    })

    if (res?.success === true) {
      let data = {
        payload,
        message: res?.message,
        result: res?.result
      }

      yield put(actionCreateSoalUjianSuccess(data))
    } else {
      let data = {
        payload,
        message: res?.message,
        errors: res?.errors
      }

      yield put(actionCreateSoalUjianFailed(data))
    }
  } catch (err) {
    let data = {
      payload,
      message: "Gagal menambah data",
      errors: null
    }

    yield put(actionCreateSoalUjianFailed(data))
  }

  yield put(actionCreateSoalUjianEnd())
}

function* sagaImportSoalUjian(params) {
  yield put(actionImportSoalUjianStart())

  const { data: { accessToken, payload } } = params
  const headerPost = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_SOAL_UJIAN}/import`
    const res = yield call(request, url, {
      method: 'POST',
      headers: headerPost,
      body: JSON.stringify({ payload })
    })

    if (res?.success === true) {
      let data = {
        payload,
        message: res?.message,
        result: res?.result
      }

      yield put(actionImportSoalUjianSuccess(data))
    } else {
      let data = {
        payload,
        message: res?.message,
        errors: res?.errors
      }

      yield put(actionImportSoalUjianFailed(data))
    }
  } catch (err) {
    let data = {
      payload,
      message: "Gagal import data",
      errors: null
    }

    yield put(actionImportSoalUjianFailed(data))
  }

  yield put(actionImportSoalUjianEnd())
}

function* sagaUpdateSoalUjian(params) {
  yield put(actionUpdateSoalUjianStart())

  const { data: { accessToken, payload, id } } = params
  const headerPost = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_SOAL_UJIAN}/${id}`
    const res = yield call(request, url, {
      method: 'PUT',
      headers: headerPost,
      body: JSON.stringify(payload)
    })

    if (res?.success === true) {
      let data = {
        payload,
        message: res?.message,
        result: res?.result
      }

      yield put(actionUpdateSoalUjianSuccess(data))
    } else {
      let data = {
        payload,
        message: res?.message,
        errors: res?.errors
      }

      yield put(actionUpdateSoalUjianFailed(data))
    }
  } catch (err) {
    let data = {
      payload,
      message: "Gagal merubah data",
      errors: null
    }

    yield put(actionUpdateSoalUjianFailed(data))
  }

  yield put(actionUpdateSoalUjianEnd())
}

function* sagaDeleteSoalUjian(params) {
  yield put(actionDeleteSoalUjianStart())

  const { data: { accessToken, id } } = params
  const header = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_SOAL_UJIAN}/${id}`
    const res = yield call(request, url, {
      method: 'DELETE',
      headers: header
    })

    if (res?.success === true) {
      yield put(actionDeleteSoalUjianSuccess())
    } else {
      yield put(actionDeleteSoalUjianFailed())
    }
  } catch (err) {
    yield put(actionDeleteSoalUjianFailed())
  }

  yield put(actionDeleteSoalUjianEnd())
}

function* soalUjianSaga() {
  yield all([
    takeEvery(actionTypes.FETCH_SOAL_UJIAN, sagaFetchSoalUjian),
    takeEvery(actionTypes.CREATE_SOAL_UJIAN, sagaCreateSoalUjian),
    takeEvery(actionTypes.UPDATE_SOAL_UJIAN, sagaUpdateSoalUjian),
    takeEvery(actionTypes.DELETE_SOAL_UJIAN, sagaDeleteSoalUjian),
    takeEvery(actionTypes.IMPORT_SOAL_UJIAN, sagaImportSoalUjian)
  ])
}

export default soalUjianSaga