import Immutable from 'seamless-immutable'

const FETCH_SESI_UJIAN = 'FETCH_SESI_UJIAN'
const FETCH_SESI_UJIAN_START = 'FETCH_SESI_UJIAN_START'
const FETCH_SESI_UJIAN_END = 'FETCH_SESI_UJIAN_END'
const FETCH_SESI_UJIAN_SUCCESS = 'FETCH_SESI_UJIAN_SUCCESS'
const FETCH_SESI_UJIAN_FAILED = 'FETCH_SESI_UJIAN_FAILED'

const CREATE_SESI_UJIAN = 'CREATE_SESI_UJIAN'
const CREATE_SESI_UJIAN_START = 'CREATE_SESI_UJIAN_START'
const CREATE_SESI_UJIAN_END = 'CREATE_SESI_UJIAN_END'
const CREATE_SESI_UJIAN_SUCCESS = 'CREATE_SESI_UJIAN_SUCCESS'
const CREATE_SESI_UJIAN_FAILED = 'CREATE_SESI_UJIAN_FAILED'

const UPDATE_SESI_UJIAN = 'UPDATE_SESI_UJIAN'
const UPDATE_SESI_UJIAN_START = 'UPDATE_SESI_UJIAN_START'
const UPDATE_SESI_UJIAN_END = 'UPDATE_SESI_UJIAN_END'
const UPDATE_SESI_UJIAN_SUCCESS = 'UPDATE_SESI_UJIAN_SUCCESS'
const UPDATE_SESI_UJIAN_FAILED = 'UPDATE_SESI_UJIAN_FAILED'

const DELETE_SESI_UJIAN = 'DELETE_SESI_UJIAN'
const DELETE_SESI_UJIAN_START = 'DELETE_SESI_UJIAN_START'
const DELETE_SESI_UJIAN_END = 'DELETE_SESI_UJIAN_END'
const DELETE_SESI_UJIAN_SUCCESS = 'DELETE_SESI_UJIAN_SUCCESS'
const DELETE_SESI_UJIAN_FAILED = 'DELETE_SESI_UJIAN_FAILED'

const RESET_FORM_SESI_UJIAN = 'RESET_FORM_SESI_UJIAN'
const RESET_DELETE_SESI_UJIAN = 'RESET_DELETE_SESI_UJIAN'

const actionTypes = Immutable({
  FETCH_SESI_UJIAN,
  FETCH_SESI_UJIAN_START,
  FETCH_SESI_UJIAN_END,
  FETCH_SESI_UJIAN_SUCCESS,
  FETCH_SESI_UJIAN_FAILED,

  CREATE_SESI_UJIAN,
  CREATE_SESI_UJIAN_START,
  CREATE_SESI_UJIAN_END,
  CREATE_SESI_UJIAN_SUCCESS,
  CREATE_SESI_UJIAN_FAILED,

  UPDATE_SESI_UJIAN,
  UPDATE_SESI_UJIAN_START,
  UPDATE_SESI_UJIAN_END,
  UPDATE_SESI_UJIAN_SUCCESS,
  UPDATE_SESI_UJIAN_FAILED,

  DELETE_SESI_UJIAN,
  DELETE_SESI_UJIAN_START,
  DELETE_SESI_UJIAN_END,
  DELETE_SESI_UJIAN_SUCCESS,
  DELETE_SESI_UJIAN_FAILED,

  RESET_FORM_SESI_UJIAN,
  RESET_DELETE_SESI_UJIAN
})

export default actionTypes
