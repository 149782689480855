import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { actionCreateSoalUjian, actionResetFormSoalUjian } from "../../store/soal-ujian/actions"
import { alert } from "../../utils/alert"
import { pushUrl } from "../../utils/url"
import Dropdown from "../../components/Form/Dropdown"
import { request } from "../../utils/request"
import NumberInput from "../../components/Form/NumberInput"
import TextArea from "../../components/Form/TextArea"
import TextEditor from "../../components/Form/TextEditor"

const optKunciJawaban = [
    { label: "A", value: "A" },
    { label: "B", value: "B" },
    { label: "C", value: "C" },
    { label: "D", value: "D" },
    { label: "E", value: "E" }
]

const SoalUjianCreate = (props) => {
    const { actionCreateSoalUjian, actionResetFormSoalUjian, accessToken, form } = props

    const [payload, setPayload] = useState({
        pertanyaan: "",
        kategori_soal_id: "",
        status: "",
        kunci_jawaban: "",
        point: "",
        jawaban_a: "",
        jawaban_b: "",
        jawaban_c: "",
        jawaban_d: "",
        jawaban_e: ""
    })
    const [errors, setErrors] = useState({})
    const [submit, setSubmit] = useState(false)
    const [optKategori, setOptKategori] = useState([])

    useEffect(() => {
        const titlePage = "Tambah Soal Ujian"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)

        getKategori()

        actionResetFormSoalUjian()

        return () => actionResetFormSoalUjian()
    }, [])

    const getKategori = async () => {
        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }
            const res = await request(process.env.REACT_APP_SERVICE_KATEGORI_SOAL + '/all-option', {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                setOptKategori(res?.result)
            }
        } catch (error) { 
            alert({ title: "Gagal", html: "Gagal meminta data ke server" })
        }
    }

    useEffect(async () => {
        if (submit === true) {
            if (form?.success === true) {
                await alert({ title: "Berhasil", html: form?.message, icon: "success" })
                pushUrl("/soal-ujian")
            } else if (form?.success === false) {
                await alert({ title: "Gagal", html: form?.message, icon: "error" })
            }
        }
    }, [form?.success, submit])

    const onChange = ((field, value) => {
        payload[field] = value

        setPayload({
            ...payload,
        })
    })

    const onSubmit = (e) => {
        if (validate() === true) {
            setSubmit(true)

            let _payload = {
                pertanyaan: payload?.pertanyaan,
                kategori_soal_id: payload?.kategori_soal_id,
                status: payload?.status,
                jawaban: [
                    {
                        kode: "A",
                        jawaban: payload?.jawaban_a,
                        jawaban_betul: payload?.kunci_jawaban === "A" ? true : false,
                        point: payload?.kunci_jawaban === "A" ? payload?.point : 0
                    },
                    {
                        kode: "B",
                        jawaban: payload?.jawaban_b,
                        jawaban_betul: payload?.kunci_jawaban === "B" ? true : false,
                        point: payload?.kunci_jawaban === "B" ? payload?.point : 0
                    },
                    {
                        kode: "C",
                        jawaban: payload?.jawaban_c,
                        jawaban_betul: payload?.kunci_jawaban === "C" ? true : false,
                        point: payload?.kunci_jawaban === "C" ? payload?.point : 0
                    },
                    {
                        kode: "D",
                        jawaban: payload?.jawaban_d,
                        jawaban_betul: payload?.kunci_jawaban === "D" ? true : false,
                        point: payload?.kunci_jawaban === "D" ? payload?.point : 0
                    },
                    {
                        kode: "E",
                        jawaban: payload?.jawaban_e,
                        jawaban_betul: payload?.kunci_jawaban === "E" ? true : false,
                        point: payload?.kunci_jawaban === "E" ? payload?.point : 0
                    }
                ]
            }

            actionCreateSoalUjian({
                payload: _payload,
                accessToken
            })
        }

        e.preventDefault()
    }

    const validate = () => {
        let jsonErrors = {}
        let hasError = false

        if (!payload?.pertanyaan || payload?.pertanyaan === "") {
            hasError = true
            jsonErrors["pertanyaan"] = "Pertanyaan harus diisi"
        }
        if (!payload?.kategori_soal_id || payload?.kategori_soal_id === "") {
            hasError = true
            jsonErrors["kategori_soal_id"] = "Kategori soal harus diisi"
        }
        if (!payload?.kunci_jawaban || payload?.kunci_jawaban === "") {
            hasError = true
            jsonErrors["kunci_jawaban"] = "Kunci Jawaban harus diisi"
        }
        if (!payload?.point || payload?.point === "") {
            hasError = true
            jsonErrors["point"] = "Point harus diisi"
        }
        if (!payload?.jawaban_a || payload?.jawaban_a === "") {
            hasError = true
            jsonErrors["jawaban_a"] = "Jawaban A harus diisi"
        }
        if (!payload?.jawaban_b || payload?.jawaban_b === "") {
            hasError = true
            jsonErrors["jawaban_b"] = "Jawaban B harus diisi"
        }
        if (!payload?.jawaban_c || payload?.jawaban_c === "") {
            hasError = true
            jsonErrors["jawaban_c"] = "Jawaban C harus diisi"
        }
        if (!payload?.jawaban_d || payload?.jawaban_d === "") {
            hasError = true
            jsonErrors["jawaban_d"] = "Jawaban D harus diisi"
        }
        if (!payload?.jawaban_e || payload?.jawaban_e === "") {
            hasError = true
            jsonErrors["jawaban_e"] = "Jawaban E harus diisi"
        }
        if (!payload?.status || payload?.status === "") {
            hasError = true
            jsonErrors["status"] = "Status harus diisi"
        }

        setErrors(jsonErrors)

        if (hasError === true) {
            return false
        } else {
            return true
        }
    }

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <Link to={process.env.REACT_APP_SUBDIR + "/soal-ujian"} className="btn btn-white mb-2 btn-md-block">Kembali</Link>

            <div className="card mt-3">
                <div className="card-body">
                    <form onSubmit={onSubmit} >
                        <div className="row">
                            <div className="col-lg-12 col-xl-10 col-xxl-8">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <TextEditor
                                            id={"pertanyaan"}
                                            name={"pertanyaan"}
                                            placeholder={"Masukkan pertanyaan"}
                                            label={"Pertanyaan"}
                                            onChange={(value) => onChange('pertanyaan', value)}
                                            required={true}
                                            value={payload?.pertanyaan}
                                            error={errors?.pertanyaan}
                                            errors={form?.errors?.pertanyaan}
                                            tabIndex={1} />
                                    </div>
                                    <div className="col-lg-6">
                                        <Dropdown
                                            id={"kategori_soal_id"}
                                            name={"kategori_soal_id"}
                                            placeholder={"Pilih kategori soal"}
                                            label={"Kategori Soal"}
                                            onChange={(e) => onChange('kategori_soal_id', e?.target?.value)}
                                            required={true}
                                            value={payload?.kategori_soal_id}
                                            error={errors?.kategori_soal_id}
                                            errors={form?.errors?.kategori_soal_id}
                                            tabIndex={2}
                                            options={optKategori} />
                                    </div>
                                    <div className="col-lg-6">
                                        <Dropdown
                                            id={"status"}
                                            name={"status"}
                                            placeholder={"Pilih status"}
                                            label={"Status"}
                                            onChange={(e) => onChange('status', e?.target?.value)}
                                            required={true}
                                            value={payload?.status}
                                            error={errors?.status}
                                            errors={form?.errors?.status}
                                            tabIndex={3}
                                            options={[
                                                { label: "Aktif", value: "Aktif" },
                                                { label: "Tidak Aktif", value: "Tidak Aktif" }
                                            ]} />
                                    </div>
                                    <div className="col-lg-6">
                                        <Dropdown
                                            id={"kunci_jawaban"}
                                            name={"kunci_jawaban"}
                                            placeholder={"Pilih kunci jawaban"}
                                            label={"Kunci Jawaban"}
                                            onChange={(e) => onChange('kunci_jawaban', e?.target?.value)}
                                            required={true}
                                            value={payload?.kunci_jawaban}
                                            error={errors?.kunci_jawaban}
                                            errors={form?.errors?.kunci_jawaban}
                                            tabIndex={4}
                                            options={optKunciJawaban} />
                                    </div>
                                    <div className="col-lg-6">
                                        <NumberInput
                                            id={"point"}
                                            name={"point"}
                                            placeholder={"Masukkan point"}
                                            label={"Point"}
                                            onChange={(e) => onChange('point', e?.target?.value)}
                                            required={true}
                                            value={payload?.point}
                                            error={errors?.point}
                                            errors={form?.errors?.point}
                                            tabIndex={5} />
                                    </div>
                                    <div className="col-lg-6">
                                        <TextArea
                                            id={"jawaban_a"}
                                            name={"jawaban_a"}
                                            placeholder={"Masukkan jawaban A"}
                                            label={"Jawaban A"}
                                            onChange={(e) => onChange('jawaban_a', e?.target?.value)}
                                            required={true}
                                            value={payload?.jawaban_a}
                                            error={errors?.jawaban_a}
                                            errors={form?.errors?.jawaban_a}
                                            tabIndex={6} />
                                    </div>
                                    <div className="col-lg-6">
                                        <TextArea
                                            id={"jawaban_b"}
                                            name={"jawaban_b"}
                                            placeholder={"Masukkan jawaban B"}
                                            label={"Jawaban B"}
                                            onChange={(e) => onChange('jawaban_b', e?.target?.value)}
                                            required={true}
                                            value={payload?.jawaban_b}
                                            error={errors?.jawaban_b}
                                            errors={form?.errors?.jawaban_b}
                                            tabIndex={7} />
                                    </div>
                                    <div className="col-lg-6">
                                        <TextArea
                                            id={"jawaban_c"}
                                            name={"jawaban_c"}
                                            placeholder={"Masukkan jawaban C"}
                                            label={"Jawaban C"}
                                            onChange={(e) => onChange('jawaban_c', e?.target?.value)}
                                            required={true}
                                            value={payload?.jawaban_c}
                                            error={errors?.jawaban_c}
                                            errors={form?.errors?.jawaban_c}
                                            tabIndex={8} />
                                    </div>
                                    <div className="col-lg-6">
                                        <TextArea
                                            id={"jawaban_d"}
                                            name={"jawaban_d"}
                                            placeholder={"Masukkan jawaban D"}
                                            label={"Jawaban D"}
                                            onChange={(e) => onChange('jawaban_d', e?.target?.value)}
                                            required={true}
                                            value={payload?.jawaban_d}
                                            error={errors?.jawaban_d}
                                            errors={form?.errors?.jawaban_d}
                                            tabIndex={9} />
                                    </div>
                                    <div className="col-lg-6">
                                        <TextArea
                                            id={"jawaban_e"}
                                            name={"jawaban_e"}
                                            placeholder={"Masukkan jawaban E"}
                                            label={"Jawaban E"}
                                            onChange={(e) => onChange('jawaban_e', e?.target?.value)}
                                            required={true}
                                            value={payload?.jawaban_e}
                                            error={errors?.jawaban_e}
                                            errors={form?.errors?.jawaban_e}
                                            tabIndex={10} />
                                    </div>
                                </div>

                                <button
                                    tabIndex={11}
                                    type="submit" className="btn btn-primary btn-md-block" disabled={form?.loading === true}>
                                    Kirim
                                    {form?.loading === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </React.Fragment>
}

const mapStateToProps = state => {
    const { auth: { accessToken }, soalUjian: { form } } = state

    return { accessToken, form }
}

export default withRouter(connect(mapStateToProps, { actionCreateSoalUjian, actionResetFormSoalUjian })(SoalUjianCreate))