import actionTypes from './actionTypes'

export const actionFetchUjianPeserta = (data) => {
  return {
    type: actionTypes.FETCH_UJIAN_PESERTA,
    data
  }
}

export const actionFetchUjianPesertaStart = () => {
  return {
    type: actionTypes.FETCH_UJIAN_PESERTA_START
  }
}

export const actionFetchUjianPesertaEnd = () => {
  return {
    type: actionTypes.FETCH_UJIAN_PESERTA_END
  }
}

export const actionFetchUjianPesertaSuccess = (data) => {
  return {
    type: actionTypes.FETCH_UJIAN_PESERTA_SUCCESS,
    data
  }
}