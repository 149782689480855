import { takeEvery, put, all, call, select } from 'redux-saga/effects'
import actionTypes from './actionTypes'
import { request } from '../../utils/request'
import { alert } from '../../utils/alert'
import { actionUpdateSesiUjianEnd, actionUpdateSesiUjianFailed, actionUpdateSesiUjianStart, actionUpdateSesiUjianSuccess, actionCreateSesiUjianEnd, actionCreateSesiUjianFailed, actionCreateSesiUjianStart, actionCreateSesiUjianSuccess, actionFetchSesiUjianEnd, actionFetchSesiUjianStart, actionFetchSesiUjianSuccess, actionDeleteSesiUjianSuccess, actionDeleteSesiUjianFailed, actionDeleteSesiUjianStart, actionDeleteSesiUjianEnd } from './actions'
import { pushUrl } from '../../utils/url'

function* sagaFetchSesiUjian(params) {
  yield put(actionFetchSesiUjianStart())

  const { data: { accessToken, search, page, sort, perPage } } = params
  const header = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let sortParam = ""
    if (sort?.by) {
      sortParam += "&sortBy=" + sort?.by
    }
    if (sort?.type) {
      sortParam += "&sortType=" + sort?.type
    }

    let perPageParam = perPage ? "&perPage=" + perPage : ""

    let pageParam = page ? "?page=" + page : "?page=1"

    let searchParam = search ? "&search=" + search : ""

    let url = `${process.env.REACT_APP_SERVICE_SESI_UJIAN}${pageParam}${searchParam}${sortParam}${perPageParam}`
    const res = yield call(request, url, {
      method: 'GET',
      headers: header
    })

    if (res?.success === true) {
      let data = {
        list: res?.result?.data,
        page: res?.result?.current_page,
        pageUrl: {
          first_page_url: res?.result?.first_page_url,
          links: res?.result?.links,
          last_page_url: res?.result?.last_page_url
        },
        last_page: res?.result?.last_page,
        from: res?.result?.from,
        to: res?.result?.to,
        total: res?.result?.total
      }
      yield put(actionFetchSesiUjianSuccess(data))
    } else {
      alert({ title: "Gagal", html: res?.message })
    }
  } catch (err) {
    alert({ title: "Gagal" })
    // yield put(actionSignInFailed())
  }

  yield put(actionFetchSesiUjianEnd())
}

function* sagaCreateSesiUjian(params) {
  yield put(actionCreateSesiUjianStart())

  const { data: { accessToken, payload } } = params
  const headerPost = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_SESI_UJIAN}`
    const res = yield call(request, url, {
      method: 'POST',
      headers: headerPost,
      body: JSON.stringify(payload)
    })

    if (res?.success === true) {
      let data = {
        payload,
        message: res?.message,
        result: res?.result
      }

      yield put(actionCreateSesiUjianSuccess(data))
    } else {
      let data = {
        payload,
        message: res?.message,
        errors: res?.errors
      }

      yield put(actionCreateSesiUjianFailed(data))
    }
  } catch (err) {
    let data = {
      payload,
      message: "Gagal menambah data",
      errors: null
    }

    yield put(actionCreateSesiUjianFailed(data))
  }

  yield put(actionCreateSesiUjianEnd())
}

function* sagaUpdateSesiUjian(params) {
  yield put(actionUpdateSesiUjianStart())

  const { data: { accessToken, payload, id } } = params
  const headerPost = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_SESI_UJIAN}/${id}`
    const res = yield call(request, url, {
      method: 'PUT',
      headers: headerPost,
      body: JSON.stringify(payload)
    })

    if (res?.success === true) {
      let data = {
        payload,
        message: res?.message,
        result: res?.result
      }

      yield put(actionUpdateSesiUjianSuccess(data))
    } else {
      let data = {
        payload,
        message: res?.message,
        errors: res?.errors
      }

      yield put(actionUpdateSesiUjianFailed(data))
    }
  } catch (err) {
    let data = {
      payload,
      message: "Gagal merubah data",
      errors: null
    }

    yield put(actionUpdateSesiUjianFailed(data))
  }

  yield put(actionUpdateSesiUjianEnd())
}

function* sagaDeleteSesiUjian(params) {
  yield put(actionDeleteSesiUjianStart())

  const { data: { accessToken, id } } = params
  const header = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_SESI_UJIAN}/${id}`
    const res = yield call(request, url, {
      method: 'DELETE',
      headers: header
    })

    if (res?.success === true) {
      yield put(actionDeleteSesiUjianSuccess())
    } else {
      yield put(actionDeleteSesiUjianFailed())
    }
  } catch (err) {
    yield put(actionDeleteSesiUjianFailed())
  }

  yield put(actionDeleteSesiUjianEnd())
}

function* sesiUjianSaga() {
  yield all([
    takeEvery(actionTypes.FETCH_SESI_UJIAN, sagaFetchSesiUjian),
    takeEvery(actionTypes.CREATE_SESI_UJIAN, sagaCreateSesiUjian),
    takeEvery(actionTypes.UPDATE_SESI_UJIAN, sagaUpdateSesiUjian),
    takeEvery(actionTypes.DELETE_SESI_UJIAN, sagaDeleteSesiUjian)
  ])
}

export default sesiUjianSaga