import actionTypes from './actionTypes'

export const actionFetchLaporanPeserta = (data) => {
  return {
    type: actionTypes.FETCH_LAPORAN_PESERTA,
    data
  }
}

export const actionFetchLaporanPesertaStart = () => {
  return {
    type: actionTypes.FETCH_LAPORAN_PESERTA_START
  }
}

export const actionFetchLaporanPesertaEnd = () => {
  return {
    type: actionTypes.FETCH_LAPORAN_PESERTA_END
  }
}

export const actionFetchLaporanPesertaSuccess = (data) => {
  return {
    type: actionTypes.FETCH_LAPORAN_PESERTA_SUCCESS,
    data
  }
}