import actionTypes from './actionTypes'

export const actionFetchSoalUjian = (data) => {
  return {
    type: actionTypes.FETCH_SOAL_UJIAN,
    data
  }
}

export const actionFetchSoalUjianStart = () => {
  return {
    type: actionTypes.FETCH_SOAL_UJIAN_START
  }
}

export const actionFetchSoalUjianEnd = () => {
  return {
    type: actionTypes.FETCH_SOAL_UJIAN_END
  }
}

export const actionFetchSoalUjianSuccess = (data) => {
  return {
    type: actionTypes.FETCH_SOAL_UJIAN_SUCCESS,
    data
  }
}

export const actionCreateSoalUjian = (data) => {
  return {
    type: actionTypes.CREATE_SOAL_UJIAN,
    data
  }
}

export const actionCreateSoalUjianStart = () => {
  return {
    type: actionTypes.CREATE_SOAL_UJIAN_START
  }
}

export const actionCreateSoalUjianEnd = () => {
  return {
    type: actionTypes.CREATE_SOAL_UJIAN_END
  }
}

export const actionCreateSoalUjianSuccess = (data) => {
  return {
    type: actionTypes.CREATE_SOAL_UJIAN_SUCCESS,
    data
  }
}

export const actionCreateSoalUjianFailed = (data) => {
  return {
    type: actionTypes.CREATE_SOAL_UJIAN_FAILED,
    data
  }
}

export const actionUpdateSoalUjian = (data) => {
  return {
    type: actionTypes.UPDATE_SOAL_UJIAN,
    data
  }
}

export const actionUpdateSoalUjianStart = () => {
  return {
    type: actionTypes.UPDATE_SOAL_UJIAN_START
  }
}

export const actionUpdateSoalUjianEnd = () => {
  return {
    type: actionTypes.UPDATE_SOAL_UJIAN_END
  }
}

export const actionUpdateSoalUjianSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_SOAL_UJIAN_SUCCESS,
    data
  }
}

export const actionUpdateSoalUjianFailed = (data) => {
  return {
    type: actionTypes.UPDATE_SOAL_UJIAN_FAILED,
    data
  }
}

export const actionResetFormSoalUjian = () => {
  return {
    type: actionTypes.RESET_FORM_SOAL_UJIAN
  }
}

export const actionDeleteSoalUjian = (data) => {
  return {
    type: actionTypes.DELETE_SOAL_UJIAN,
    data
  }
}

export const actionDeleteSoalUjianStart = () => {
  return {
    type: actionTypes.DELETE_SOAL_UJIAN_START
  }
}

export const actionDeleteSoalUjianEnd = () => {
  return {
    type: actionTypes.DELETE_SOAL_UJIAN_END
  }
}

export const actionDeleteSoalUjianSuccess = () => {
  return {
    type: actionTypes.DELETE_SOAL_UJIAN_SUCCESS
  }
}

export const actionDeleteSoalUjianFailed = () => {
  return {
    type: actionTypes.DELETE_SOAL_UJIAN_FAILED
  }
}

export const actionResetDeleteSoalUjian = () => {
  return {
    type: actionTypes.RESET_DELETE_SOAL_UJIAN
  }
}

export const actionImportSoalUjian = (data) => {
  return {
    type: actionTypes.IMPORT_SOAL_UJIAN,
    data
  }
}

export const actionImportSoalUjianStart = () => {
  return {
    type: actionTypes.IMPORT_SOAL_UJIAN_START
  }
}

export const actionImportSoalUjianEnd = () => {
  return {
    type: actionTypes.IMPORT_SOAL_UJIAN_END
  }
}

export const actionImportSoalUjianSuccess = (data) => {
  return {
    type: actionTypes.IMPORT_SOAL_UJIAN_SUCCESS,
    data
  }
}

export const actionImportSoalUjianFailed = (data) => {
  return {
    type: actionTypes.IMPORT_SOAL_UJIAN_FAILED,
    data
  }
}