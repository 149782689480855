import actionTypes from './actionTypes'

export const actionFetchPesertaDaftarUlang = (data) => {
  return {
    type: actionTypes.FETCH_PESERTA_DAFTAR_ULANG,
    data
  }
}

export const actionFetchPesertaDaftarUlangStart = () => {
  return {
    type: actionTypes.FETCH_PESERTA_DAFTAR_ULANG_START
  }
}

export const actionFetchPesertaDaftarUlangEnd = () => {
  return {
    type: actionTypes.FETCH_PESERTA_DAFTAR_ULANG_END
  }
}

export const actionFetchPesertaDaftarUlangSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PESERTA_DAFTAR_ULANG_SUCCESS,
    data
  }
}

export const actionUpdatePesertaDaftarUlang = (data) => {
  return {
    type: actionTypes.UPDATE_PESERTA_DAFTAR_ULANG,
    data
  }
}

export const actionUpdatePesertaDaftarUlangStart = () => {
  return {
    type: actionTypes.UPDATE_PESERTA_DAFTAR_ULANG_START
  }
}

export const actionUpdatePesertaDaftarUlangEnd = () => {
  return {
    type: actionTypes.UPDATE_PESERTA_DAFTAR_ULANG_END
  }
}

export const actionUpdatePesertaDaftarUlangSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_PESERTA_DAFTAR_ULANG_SUCCESS,
    data
  }
}

export const actionUpdatePesertaDaftarUlangFailed = (data) => {
  return {
    type: actionTypes.UPDATE_PESERTA_DAFTAR_ULANG_FAILED,
    data
  }
}

export const actionResetFormPesertaDaftarUlang = () => {
  return {
    type: actionTypes.RESET_FORM_PESERTA_DAFTAR_ULANG
  }
}