import Immutable from 'seamless-immutable'

const FETCH_SOAL_UJIAN = 'FETCH_SOAL_UJIAN'
const FETCH_SOAL_UJIAN_START = 'FETCH_SOAL_UJIAN_START'
const FETCH_SOAL_UJIAN_END = 'FETCH_SOAL_UJIAN_END'
const FETCH_SOAL_UJIAN_SUCCESS = 'FETCH_SOAL_UJIAN_SUCCESS'
const FETCH_SOAL_UJIAN_FAILED = 'FETCH_SOAL_UJIAN_FAILED'

const IMPORT_SOAL_UJIAN = 'IMPORT_SOAL_UJIAN'
const IMPORT_SOAL_UJIAN_START = 'IMPORT_SOAL_UJIAN_START'
const IMPORT_SOAL_UJIAN_END = 'IMPORT_SOAL_UJIAN_END'
const IMPORT_SOAL_UJIAN_SUCCESS = 'IMPORT_SOAL_UJIAN_SUCCESS'
const IMPORT_SOAL_UJIAN_FAILED = 'IMPORT_SOAL_UJIAN_FAILED'

const CREATE_SOAL_UJIAN = 'CREATE_SOAL_UJIAN'
const CREATE_SOAL_UJIAN_START = 'CREATE_SOAL_UJIAN_START'
const CREATE_SOAL_UJIAN_END = 'CREATE_SOAL_UJIAN_END'
const CREATE_SOAL_UJIAN_SUCCESS = 'CREATE_SOAL_UJIAN_SUCCESS'
const CREATE_SOAL_UJIAN_FAILED = 'CREATE_SOAL_UJIAN_FAILED'

const UPDATE_SOAL_UJIAN = 'UPDATE_SOAL_UJIAN'
const UPDATE_SOAL_UJIAN_START = 'UPDATE_SOAL_UJIAN_START'
const UPDATE_SOAL_UJIAN_END = 'UPDATE_SOAL_UJIAN_END'
const UPDATE_SOAL_UJIAN_SUCCESS = 'UPDATE_SOAL_UJIAN_SUCCESS'
const UPDATE_SOAL_UJIAN_FAILED = 'UPDATE_SOAL_UJIAN_FAILED'

const DELETE_SOAL_UJIAN = 'DELETE_SOAL_UJIAN'
const DELETE_SOAL_UJIAN_START = 'DELETE_SOAL_UJIAN_START'
const DELETE_SOAL_UJIAN_END = 'DELETE_SOAL_UJIAN_END'
const DELETE_SOAL_UJIAN_SUCCESS = 'DELETE_SOAL_UJIAN_SUCCESS'
const DELETE_SOAL_UJIAN_FAILED = 'DELETE_SOAL_UJIAN_FAILED'

const RESET_FORM_SOAL_UJIAN = 'RESET_FORM_SOAL_UJIAN'
const RESET_DELETE_SOAL_UJIAN = 'RESET_DELETE_SOAL_UJIAN'

const actionTypes = Immutable({
  FETCH_SOAL_UJIAN,
  FETCH_SOAL_UJIAN_START,
  FETCH_SOAL_UJIAN_END,
  FETCH_SOAL_UJIAN_SUCCESS,
  FETCH_SOAL_UJIAN_FAILED,

  IMPORT_SOAL_UJIAN,
  IMPORT_SOAL_UJIAN_START,
  IMPORT_SOAL_UJIAN_END,
  IMPORT_SOAL_UJIAN_SUCCESS,
  IMPORT_SOAL_UJIAN_FAILED,

  CREATE_SOAL_UJIAN,
  CREATE_SOAL_UJIAN_START,
  CREATE_SOAL_UJIAN_END,
  CREATE_SOAL_UJIAN_SUCCESS,
  CREATE_SOAL_UJIAN_FAILED,

  UPDATE_SOAL_UJIAN,
  UPDATE_SOAL_UJIAN_START,
  UPDATE_SOAL_UJIAN_END,
  UPDATE_SOAL_UJIAN_SUCCESS,
  UPDATE_SOAL_UJIAN_FAILED,

  DELETE_SOAL_UJIAN,
  DELETE_SOAL_UJIAN_START,
  DELETE_SOAL_UJIAN_END,
  DELETE_SOAL_UJIAN_SUCCESS,
  DELETE_SOAL_UJIAN_FAILED,

  RESET_FORM_SOAL_UJIAN,
  RESET_DELETE_SOAL_UJIAN
})

export default actionTypes
