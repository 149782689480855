import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, useLocation, withRouter } from "react-router-dom"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { actionFetchLaporanPeserta } from "../../store/laporan-peserta/actions"
import Pagination from "../../components/Table/Pagination"
import { changeUrl } from "../../utils/url"
import LimitTable from "../../components/Table/LimitTable"
import SearchTable from "../../components/Table/SearchTable"
import HeadTable from "../../components/Table/HeadTable"
import Excel from 'exceljs'
import { saveAs } from 'file-saver'
import { alert } from "../../utils/alert"
import { request } from "../../utils/request"
import { dateTime, strtotime } from "../../utils/formatter"

const columnToExport = [
    { header: 'Nomor', key: 'nomor', width: 10 },
    { header: 'Nama', key: 'nama', width: 20 },
    { header: 'NIK', key: 'nik', width: 20 },
    { header: 'Email', key: 'email', width: 20 },
    { header: '5 Digit Nomor HP', key: 'nomor_hp', width: 20 },
]

const workSheetName = 'Sheet 1'

const LaporanPesertaIndex = (props) => {
    const { actionFetchLaporanPeserta, accessToken, index } = props
    const [sort, setSort] = useState({
        by: null,
        type: null
    })
    const [search, setSearch] = useState("")
    const [searchValue, setSearchValue] = useState("")
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState("")

    const location = useLocation()
    const [pathname, setPathname] = useState(null)

    const workbook = new Excel.Workbook()
    const [loadingExport, setLoadingExport] = useState(false)

    useEffect(() => {
        let arrLocation = location?.pathname?.split("/")

        if (arrLocation?.length > 0) {
            setPathname("/" + arrLocation[1])
        }
    }, [location])

    useEffect(() => {
        let titlePage = "Laporan Peserta"

        if (pathname === "/laporan-peserta-daftar-ulang") {
            titlePage = "Laporan Calon Peserta Ujian"
        } else if (pathname === "/laporan-peserta-belum-daftar-ulang") {
            titlePage = "Laporan Belum Daftar Ulang"
        } else if (pathname === "/laporan-peserta-ikut-ujian") {
            titlePage = "Laporan Peserta Ujian"
        }

        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)

        if (pathname) {
            fetchData()
        }
    }, [pathname])

    const fetchData = () => {
        const query = new URLSearchParams(props.location.search)

        if (query.get('page')) {
            setPage(parseInt(query.get('page')))
        } else {
            setPage(1)
        }

        if (query.get('search')) {
            setSearch(query.get('search'))
            setSearchValue(query.get('search'))
        }

        if (query.get('perPage')) {
            setPerPage(parseInt(query.get('perPage')))
        } else {
            setPerPage(10)
        }

        if (query.get('sortBy') || query.get('sortType')) {
            setSort({
                by: query.get('sortBy') || null,
                type: query.get('sortType') || null,
            })
        }

        actionFetchLaporanPeserta({
            filter: pathname,
            search: query.get('search') || "",
            page: parseInt(query.get('page')) || 1,
            sort: {
                by: query.get('sortBy') || null,
                type: query.get('sortType') || null,
            },
            perPage: parseInt(query.get('perPage')) || 10,
            accessToken
        })
    }

    const sortData = (field, e) => {
        let sortType = sort?.type === "desc" ? "asc" : "desc"
        setSort({
            by: field,
            type: sortType
        })
        actionFetchLaporanPeserta({
            filter: pathname,
            search,
            page,
            sort: {
                by: field,
                type: sortType
            },
            perPage,
            accessToken
        })
        e.preventDefault()
    }

    const changePerPage = (val) => {
        setPerPage(val)
        actionFetchLaporanPeserta({
            filter: pathname,
            search,
            page,
            sort,
            perPage: val,
            accessToken
        })
    }

    useEffect(() => {
        let queryParams = {}
        if (page) {
            queryParams['page'] = page
        }
        if (search) {
            queryParams['search'] = search
        }
        if (sort?.by) {
            queryParams['sortBy'] = sort?.by
        }
        if (sort?.type) {
            queryParams['sortType'] = sort?.type
        }
        if (perPage) {
            queryParams['perPage'] = perPage
        }

        let query = new URLSearchParams(queryParams).toString()

        changeUrl(pathname + "?" + query)
    }, [page, search, sort?.by, sort?.type, perPage])

    const pageData = (val, e) => {
        setPage(val)
        actionFetchLaporanPeserta({
            filter: pathname,
            search,
            page: val,
            sort,
            perPage,
            accessToken
        })
        e.preventDefault()
    }

    const onSearch = (e) => {
        setSearch(searchValue)
        actionFetchLaporanPeserta({
            filter: pathname,
            search: searchValue,
            page,
            sort,
            perPage,
            accessToken
        })
        e.preventDefault()
    }

    const onChangeSearch = (val) => {
        setSearchValue(val)
    }

    useEffect(() => {
        if (page && index?.last_page) {
            if (page > index?.last_page) {
                setPage(index?.last_page)
                actionFetchLaporanPeserta({
                    filter: pathname,
                    search,
                    page: index?.last_page,
                    sort,
                    perPage,
                    accessToken
                })
            } else if (page < 0) {
                setPage(1)
                actionFetchLaporanPeserta({
                    filter: pathname,
                    search,
                    page: 1,
                    sort,
                    perPage,
                    accessToken
                })
            }
        }
    }, [page, index?.last_page])

    const exportExcel = async () => {
        setLoadingExport(true)

        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }
            let status = pathname.replace("/", "")
            const res = await request(process.env.REACT_APP_SERVICE_LAPORAN_PESERTA + '/export?status=' + status, {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                try {
                    let titlePage = "Laporan Peserta"

                    if (pathname === "/laporan-peserta-daftar-ulang") {
                        titlePage = "Laporan Calon Peserta Ujian"
                    } else if (pathname === "/laporan-peserta-belum-daftar-ulang") {
                        titlePage = "Laporan Belum Daftar Ulang"
                    } else if (pathname === "/laporan-peserta-ikut-ujian") {
                        titlePage = "Laporan Peserta Ujian"
                    }

                    const fileName = titlePage

                    // creating one worksheet in workbook
                    const worksheet = workbook.addWorksheet(workSheetName)

                    // add worksheet columns
                    // each columns contains header and its mapping key from data
                    worksheet.columns = columnToExport

                    // updated the font for first row.
                    worksheet.getRow(1).font = { bold: true }

                    // loop through all of the columns and set the alignment with width.
                    worksheet.columns.forEach(column => {
                        column.width = column.width
                        // column.alignment = { horizontal: 'center' }
                    })

                    let dataToExport = res?.result

                    // loop through data and add each one to worksheet
                    dataToExport.forEach((singleData, keyData) => {
                        let rowData = {
                            ...singleData,
                            nomor: keyData + 1
                        }
                        worksheet.addRow(rowData)
                    })

                    // loop through all of the rows and set the outline style.
                    worksheet.eachRow({ includeEmpty: false }, row => {
                        // store each cell to currentCell
                        const currentCell = row._cells

                        // loop through currentCell to apply border only for the non-empty cell of excel
                        currentCell.forEach(singleCell => {
                            // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
                            const cellAddress = singleCell._address

                            worksheet.getCell(cellAddress).border = {
                                top: { style: 'thin' },
                                left: { style: 'thin' },
                                bottom: { style: 'thin' },
                                right: { style: 'thin' }
                            }
                        })
                    })

                    // write the content using writeBuffer
                    const buf = await workbook.xlsx.writeBuffer()

                    // download the processed file
                    saveAs(new Blob([buf]), `${fileName} - ${dateTime(strtotime(new Date()), "YYYYMMDD HHmmss")}.xlsx`)
                    setLoadingExport(false)
                } catch (error) {
                    // console.log({ error })
                    setLoadingExport(false)
                } finally {
                    // removing worksheet's instance to create new one
                    workbook.removeWorksheet(workSheetName)
                }
            } else {
                alert({ title: "Gagal", html: "Gagal meminta data ke server" })
                setLoadingExport(false)
            }
        } catch (error) {
            alert({ title: "Gagal", html: "Gagal meminta data ke server" })
            setLoadingExport(false)
        }
    }

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col-lg-8">
                    {
                        pathname ?
                            <button type="button" onClick={() => exportExcel()} className="btn btn-primary mb-2 btn-md-block" disabled={loadingExport === true}>
                                Unduh
                                {loadingExport === true ? <span className="spinner-border ms-2" role="nomor_hp" aria-hidden="true"></span> : null}
                            </button>
                            : null
                    }
                </div>
                <div className="col-lg-4">
                    <SearchTable onSearch={onSearch} onChange={onChangeSearch} value={searchValue} />
                </div>
            </div>

            {
                index?.total > 0 ?
                    <p className="mt-2">Menampilkan {index?.from} - {index?.to} dari {index?.total} data</p>
                    :
                    <p className="mt-2">Menampilkan 0 data</p>
            }

            <div className="card mt-3">
                <div className="table-responsive grid-view">
                    <table className="table">
                        <HeadTable
                            sort={sort}
                            sortData={sortData}
                            columns={[
                                { type: "label", label: "No." },
                                { type: "sortable", label: "Nama", field: "nama" },
                                { type: "sortable", label: "NIK", field: "nik" },
                                { type: "sortable", label: "Nomor HP", field: "nomor_hp" },
                                { type: "sortable", label: "Email", field: "email" },
                                { type: "sortable", label: "Waktu Ujian", field: "waktu_ujian", hidden: pathname === null || pathname === "/laporan-peserta-belum-daftar-ulang" },
                                // { type: "label", label: "" }
                            ]}
                        />
                        <tbody className="table-border-bottom-0">
                            {
                                index?.list?.length > 0 ?
                                    index?.list?.map((val, key) => {
                                        return <tr key={key}>
                                            <td><strong>{((page - 1) * perPage) + key + 1}</strong></td>
                                            <td>{val?.nama}</td>
                                            <td>{val?.nik}</td>
                                            <td>{val?.nomor_hp}</td>
                                            <td>{val?.email}</td>
                                            {
                                                pathname === "/laporan-peserta-daftar-ulang" || pathname === "/laporan-peserta-ikut-ujian" ? <td>{val?.waktu_ujian}</td> : null
                                            }
                                            {/* <td className="text-end">
                                                <Link to={{
                                                    pathname: process.env.REACT_APP_SUBDIR + pathname + "/detail/" + val?.id,
                                                    state: val
                                                }} className="btn btn-info btn-sm">Detail</Link>
                                            </td> */}
                                        </tr>
                                    })
                                    : <tr>
                                        <td colSpan={7}>Data tidak ditemukan</td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-lg-8">
                    <Pagination page={page} pageUrl={index?.pageUrl} onChange={pageData} last_page={index?.last_page} />
                </div>
                <div className="col-lg-4">
                    <LimitTable perPage={perPage} onChange={changePerPage} />
                </div>
            </div>
        </div>
    </React.Fragment >
}

const mapStateToProps = state => {
    const { auth: { accessToken }, laporanPeserta: { index } } = state

    return { accessToken, index }
}

export default withRouter(connect(mapStateToProps, { actionFetchLaporanPeserta })(LaporanPesertaIndex))