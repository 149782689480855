import actionTypes from './actionTypes'

export const actionFetchSesiUjian = (data) => {
  return {
    type: actionTypes.FETCH_SESI_UJIAN,
    data
  }
}

export const actionFetchSesiUjianStart = () => {
  return {
    type: actionTypes.FETCH_SESI_UJIAN_START
  }
}

export const actionFetchSesiUjianEnd = () => {
  return {
    type: actionTypes.FETCH_SESI_UJIAN_END
  }
}

export const actionFetchSesiUjianSuccess = (data) => {
  return {
    type: actionTypes.FETCH_SESI_UJIAN_SUCCESS,
    data
  }
}

export const actionCreateSesiUjian = (data) => {
  return {
    type: actionTypes.CREATE_SESI_UJIAN,
    data
  }
}

export const actionCreateSesiUjianStart = () => {
  return {
    type: actionTypes.CREATE_SESI_UJIAN_START
  }
}

export const actionCreateSesiUjianEnd = () => {
  return {
    type: actionTypes.CREATE_SESI_UJIAN_END
  }
}

export const actionCreateSesiUjianSuccess = (data) => {
  return {
    type: actionTypes.CREATE_SESI_UJIAN_SUCCESS,
    data
  }
}

export const actionCreateSesiUjianFailed = (data) => {
  return {
    type: actionTypes.CREATE_SESI_UJIAN_FAILED,
    data
  }
}

export const actionUpdateSesiUjian = (data) => {
  return {
    type: actionTypes.UPDATE_SESI_UJIAN,
    data
  }
}

export const actionUpdateSesiUjianStart = () => {
  return {
    type: actionTypes.UPDATE_SESI_UJIAN_START
  }
}

export const actionUpdateSesiUjianEnd = () => {
  return {
    type: actionTypes.UPDATE_SESI_UJIAN_END
  }
}

export const actionUpdateSesiUjianSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_SESI_UJIAN_SUCCESS,
    data
  }
}

export const actionUpdateSesiUjianFailed = (data) => {
  return {
    type: actionTypes.UPDATE_SESI_UJIAN_FAILED,
    data
  }
}

export const actionResetFormSesiUjian = () => {
  return {
    type: actionTypes.RESET_FORM_SESI_UJIAN
  }
}

export const actionDeleteSesiUjian = (data) => {
  return {
    type: actionTypes.DELETE_SESI_UJIAN,
    data
  }
}

export const actionDeleteSesiUjianStart = () => {
  return {
    type: actionTypes.DELETE_SESI_UJIAN_START
  }
}

export const actionDeleteSesiUjianEnd = () => {
  return {
    type: actionTypes.DELETE_SESI_UJIAN_END
  }
}

export const actionDeleteSesiUjianSuccess = () => {
  return {
    type: actionTypes.DELETE_SESI_UJIAN_SUCCESS
  }
}

export const actionDeleteSesiUjianFailed = () => {
  return {
    type: actionTypes.DELETE_SESI_UJIAN_FAILED
  }
}

export const actionResetDeleteSesiUjian = () => {
  return {
    type: actionTypes.RESET_DELETE_SESI_UJIAN
  }
}