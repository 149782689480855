import { takeEvery, put, all, call, select } from 'redux-saga/effects'
import actionTypes from './actionTypes'
import { request } from '../../utils/request'
import { alert } from '../../utils/alert'
import { actionUpdatePesertaUjianEnd, actionUpdatePesertaUjianFailed, actionUpdatePesertaUjianStart, actionUpdatePesertaUjianSuccess, actionCreatePesertaUjianEnd, actionCreatePesertaUjianFailed, actionCreatePesertaUjianStart, actionCreatePesertaUjianSuccess, actionFetchPesertaUjianEnd, actionFetchPesertaUjianStart, actionFetchPesertaUjianSuccess, actionDeletePesertaUjianSuccess, actionDeletePesertaUjianFailed, actionDeletePesertaUjianStart, actionDeletePesertaUjianEnd, actionImportPesertaUjianEnd, actionImportPesertaUjianFailed, actionImportPesertaUjianSuccess, actionImportPesertaUjianStart } from './actions'
import { pushUrl } from '../../utils/url'

function* sagaFetchPesertaUjian(params) {
  yield put(actionFetchPesertaUjianStart())

  const { data: { accessToken, search, page, sort, perPage } } = params
  const header = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let sortParam = ""
    if (sort?.by) {
      sortParam += "&sortBy=" + sort?.by
    }
    if (sort?.type) {
      sortParam += "&sortType=" + sort?.type
    }

    let perPageParam = perPage ? "&perPage=" + perPage : ""

    let pageParam = page ? "?page=" + page : "?page=1"

    let searchParam = search ? "&search=" + search : ""

    let url = `${process.env.REACT_APP_SERVICE_PESERTA_UJIAN}${pageParam}${searchParam}${sortParam}${perPageParam}`
    const res = yield call(request, url, {
      method: 'GET',
      headers: header
    })

    if (res?.success === true) {
      let data = {
        list: res?.result?.data,
        page: res?.result?.current_page,
        pageUrl: {
          first_page_url: res?.result?.first_page_url,
          links: res?.result?.links,
          last_page_url: res?.result?.last_page_url
        },
        last_page: res?.result?.last_page,
        from: res?.result?.from,
        to: res?.result?.to,
        total: res?.result?.total
      }
      yield put(actionFetchPesertaUjianSuccess(data))
    } else {
      alert({ title: "Gagal", html: res?.message })
    }
  } catch (err) {
    alert({ title: "Gagal" })
    // yield put(actionSignInFailed())
  }

  yield put(actionFetchPesertaUjianEnd())
}

function* sagaCreatePesertaUjian(params) {
  yield put(actionCreatePesertaUjianStart())

  const { data: { accessToken, payload } } = params
  const headerPost = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_PESERTA_UJIAN}`
    const res = yield call(request, url, {
      method: 'POST',
      headers: headerPost,
      body: JSON.stringify(payload)
    })

    if (res?.success === true) {
      let data = {
        payload,
        message: res?.message,
        result: res?.result
      }

      yield put(actionCreatePesertaUjianSuccess(data))
    } else {
      let data = {
        payload,
        message: res?.message,
        errors: res?.errors
      }

      yield put(actionCreatePesertaUjianFailed(data))
    }
  } catch (err) {
    let data = {
      payload,
      message: "Gagal menambah data",
      errors: null
    }

    yield put(actionCreatePesertaUjianFailed(data))
  }

  yield put(actionCreatePesertaUjianEnd())
}

function* sagaImportPesertaUjian(params) {
  yield put(actionImportPesertaUjianStart())

  const { data: { accessToken, payload } } = params
  const headerPost = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_PESERTA_UJIAN}/import`
    const res = yield call(request, url, {
      method: 'POST',
      headers: headerPost,
      body: JSON.stringify({ payload })
    })

    if (res?.success === true) {
      let data = {
        payload,
        message: res?.message,
        result: res?.result
      }

      yield put(actionImportPesertaUjianSuccess(data))
    } else {
      let data = {
        payload,
        message: res?.message,
        errors: res?.errors
      }

      yield put(actionImportPesertaUjianFailed(data))
    }
  } catch (err) {
    let data = {
      payload,
      message: "Gagal import data",
      errors: null
    }

    yield put(actionImportPesertaUjianFailed(data))
  }

  yield put(actionImportPesertaUjianEnd())
}

function* sagaUpdatePesertaUjian(params) {
  yield put(actionUpdatePesertaUjianStart())

  const { data: { accessToken, payload, id } } = params
  const headerPost = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_PESERTA_UJIAN}/${id}`
    const res = yield call(request, url, {
      method: 'PUT',
      headers: headerPost,
      body: JSON.stringify(payload)
    })

    if (res?.success === true) {
      let data = {
        payload,
        message: res?.message,
        result: res?.result
      }

      yield put(actionUpdatePesertaUjianSuccess(data))
    } else {
      let data = {
        payload,
        message: res?.message,
        errors: res?.errors
      }

      yield put(actionUpdatePesertaUjianFailed(data))
    }
  } catch (err) {
    let data = {
      payload,
      message: "Gagal merubah data",
      errors: null
    }

    yield put(actionUpdatePesertaUjianFailed(data))
  }

  yield put(actionUpdatePesertaUjianEnd())
}

function* sagaDeletePesertaUjian(params) {
  yield put(actionDeletePesertaUjianStart())

  const { data: { accessToken, id } } = params
  const header = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_PESERTA_UJIAN}/${id}`
    const res = yield call(request, url, {
      method: 'DELETE',
      headers: header
    })

    if (res?.success === true) {
      yield put(actionDeletePesertaUjianSuccess())
    } else {
      yield put(actionDeletePesertaUjianFailed())
    }
  } catch (err) {
    yield put(actionDeletePesertaUjianFailed())
  }

  yield put(actionDeletePesertaUjianEnd())
}

function* pesertaUjianSaga() {
  yield all([
    takeEvery(actionTypes.FETCH_PESERTA_UJIAN, sagaFetchPesertaUjian),
    takeEvery(actionTypes.CREATE_PESERTA_UJIAN, sagaCreatePesertaUjian),
    takeEvery(actionTypes.UPDATE_PESERTA_UJIAN, sagaUpdatePesertaUjian),
    takeEvery(actionTypes.DELETE_PESERTA_UJIAN, sagaDeletePesertaUjian),
    takeEvery(actionTypes.IMPORT_PESERTA_UJIAN, sagaImportPesertaUjian)
  ])
}

export default pesertaUjianSaga