import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { alert } from "../../utils/alert"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { request } from "../../utils/request"

const PeringkatUjianDetail = (props) => {
    const { accessToken, match: { params: { id } }, history: { location: { state } } } = props
    const [data, setData] = useState(null)
    const [noData, setNoData] = useState(false)

    useEffect(() => {
        const titlePage = "Detail Peringkat Ujian"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)

        if (state) {
            setData(state)
        } else {
            getData()
        }
    }, [])

    const getData = async () => {
        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }
            const res = await request(process.env.REACT_APP_SERVICE_PERINGKAT_UJIAN + '/' + id, {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                setData(res?.result)
            } else {
                setNoData(true)
            }
        } catch (error) {
            setNoData(true)
            alert({ title: "Gagal", html: "Gagal meminta data ke server" })
        }
    }

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <Link to={process.env.REACT_APP_SUBDIR + "/peringkat-ujian"} className="btn btn-white mb-2 btn-md-block">Kembali</Link>

            <div className="card mt-3">
                <div className="card-body">
                    {
                        noData === true ?
                            <span>Data tidak ditemukan</span>
                            :
                            <div className="table-responsive text-nowrap detail-view">
                                <table className="table table-bordered">
                                    <tbody className="table-border-bottom-0">
                                        <tr>
                                            <th style={{ width: "25%" }}>
                                                <strong>Peringkat</strong>
                                            </th>
                                            <td>{data?.peringkat}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ width: "25%" }}>
                                                <strong>Nama</strong>
                                            </th>
                                            <td>{data?.user?.name}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ width: "25%" }}>
                                                <strong>NIK</strong>
                                            </th>
                                            <td>{data?.peserta?.nik}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ width: "25%" }}>
                                                <strong>Status</strong>
                                            </th>
                                            <td>{data?.status}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                    }
                </div>
            </div>
        </div>
    </React.Fragment>
}

const mapStateToProps = state => {
    const { auth: { accessToken } } = state

    return { accessToken }
}

export default withRouter(connect(mapStateToProps, {})(PeringkatUjianDetail))