import Immutable from 'seamless-immutable'

const FETCH_KATEGORI_SOAL = 'FETCH_KATEGORI_SOAL'
const FETCH_KATEGORI_SOAL_START = 'FETCH_KATEGORI_SOAL_START'
const FETCH_KATEGORI_SOAL_END = 'FETCH_KATEGORI_SOAL_END'
const FETCH_KATEGORI_SOAL_SUCCESS = 'FETCH_KATEGORI_SOAL_SUCCESS'
const FETCH_KATEGORI_SOAL_FAILED = 'FETCH_KATEGORI_SOAL_FAILED'

const CREATE_KATEGORI_SOAL = 'CREATE_KATEGORI_SOAL'
const CREATE_KATEGORI_SOAL_START = 'CREATE_KATEGORI_SOAL_START'
const CREATE_KATEGORI_SOAL_END = 'CREATE_KATEGORI_SOAL_END'
const CREATE_KATEGORI_SOAL_SUCCESS = 'CREATE_KATEGORI_SOAL_SUCCESS'
const CREATE_KATEGORI_SOAL_FAILED = 'CREATE_KATEGORI_SOAL_FAILED'

const UPDATE_KATEGORI_SOAL = 'UPDATE_KATEGORI_SOAL'
const UPDATE_KATEGORI_SOAL_START = 'UPDATE_KATEGORI_SOAL_START'
const UPDATE_KATEGORI_SOAL_END = 'UPDATE_KATEGORI_SOAL_END'
const UPDATE_KATEGORI_SOAL_SUCCESS = 'UPDATE_KATEGORI_SOAL_SUCCESS'
const UPDATE_KATEGORI_SOAL_FAILED = 'UPDATE_KATEGORI_SOAL_FAILED'

const DELETE_KATEGORI_SOAL = 'DELETE_KATEGORI_SOAL'
const DELETE_KATEGORI_SOAL_START = 'DELETE_KATEGORI_SOAL_START'
const DELETE_KATEGORI_SOAL_END = 'DELETE_KATEGORI_SOAL_END'
const DELETE_KATEGORI_SOAL_SUCCESS = 'DELETE_KATEGORI_SOAL_SUCCESS'
const DELETE_KATEGORI_SOAL_FAILED = 'DELETE_KATEGORI_SOAL_FAILED'

const RESET_FORM_KATEGORI_SOAL = 'RESET_FORM_KATEGORI_SOAL'
const RESET_DELETE_KATEGORI_SOAL = 'RESET_DELETE_KATEGORI_SOAL'

const actionTypes = Immutable({
  FETCH_KATEGORI_SOAL,
  FETCH_KATEGORI_SOAL_START,
  FETCH_KATEGORI_SOAL_END,
  FETCH_KATEGORI_SOAL_SUCCESS,
  FETCH_KATEGORI_SOAL_FAILED,

  CREATE_KATEGORI_SOAL,
  CREATE_KATEGORI_SOAL_START,
  CREATE_KATEGORI_SOAL_END,
  CREATE_KATEGORI_SOAL_SUCCESS,
  CREATE_KATEGORI_SOAL_FAILED,

  UPDATE_KATEGORI_SOAL,
  UPDATE_KATEGORI_SOAL_START,
  UPDATE_KATEGORI_SOAL_END,
  UPDATE_KATEGORI_SOAL_SUCCESS,
  UPDATE_KATEGORI_SOAL_FAILED,

  DELETE_KATEGORI_SOAL,
  DELETE_KATEGORI_SOAL_START,
  DELETE_KATEGORI_SOAL_END,
  DELETE_KATEGORI_SOAL_SUCCESS,
  DELETE_KATEGORI_SOAL_FAILED,

  RESET_FORM_KATEGORI_SOAL,
  RESET_DELETE_KATEGORI_SOAL
})

export default actionTypes
