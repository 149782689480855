import Immutable from 'seamless-immutable'

const FETCH_PERINGKAT_UJIAN = 'FETCH_PERINGKAT_UJIAN'
const FETCH_PERINGKAT_UJIAN_START = 'FETCH_PERINGKAT_UJIAN_START'
const FETCH_PERINGKAT_UJIAN_END = 'FETCH_PERINGKAT_UJIAN_END'
const FETCH_PERINGKAT_UJIAN_SUCCESS = 'FETCH_PERINGKAT_UJIAN_SUCCESS'
const FETCH_PERINGKAT_UJIAN_FAILED = 'FETCH_PERINGKAT_UJIAN_FAILED'

const actionTypes = Immutable({
  FETCH_PERINGKAT_UJIAN,
  FETCH_PERINGKAT_UJIAN_START,
  FETCH_PERINGKAT_UJIAN_END,
  FETCH_PERINGKAT_UJIAN_SUCCESS,
  FETCH_PERINGKAT_UJIAN_FAILED
})

export default actionTypes
