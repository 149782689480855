import actionTypes from './actionTypes'

export const actionFetchPeringkatUjian = (data) => {
  return {
    type: actionTypes.FETCH_PERINGKAT_UJIAN,
    data
  }
}

export const actionFetchPeringkatUjianStart = () => {
  return {
    type: actionTypes.FETCH_PERINGKAT_UJIAN_START
  }
}

export const actionFetchPeringkatUjianEnd = () => {
  return {
    type: actionTypes.FETCH_PERINGKAT_UJIAN_END
  }
}

export const actionFetchPeringkatUjianSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PERINGKAT_UJIAN_SUCCESS,
    data
  }
}