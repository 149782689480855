import actionTypes from './actionTypes'

export const actionFetchKategoriSoal = (data) => {
  return {
    type: actionTypes.FETCH_KATEGORI_SOAL,
    data
  }
}

export const actionFetchKategoriSoalStart = () => {
  return {
    type: actionTypes.FETCH_KATEGORI_SOAL_START
  }
}

export const actionFetchKategoriSoalEnd = () => {
  return {
    type: actionTypes.FETCH_KATEGORI_SOAL_END
  }
}

export const actionFetchKategoriSoalSuccess = (data) => {
  return {
    type: actionTypes.FETCH_KATEGORI_SOAL_SUCCESS,
    data
  }
}

export const actionCreateKategoriSoal = (data) => {
  return {
    type: actionTypes.CREATE_KATEGORI_SOAL,
    data
  }
}

export const actionCreateKategoriSoalStart = () => {
  return {
    type: actionTypes.CREATE_KATEGORI_SOAL_START
  }
}

export const actionCreateKategoriSoalEnd = () => {
  return {
    type: actionTypes.CREATE_KATEGORI_SOAL_END
  }
}

export const actionCreateKategoriSoalSuccess = (data) => {
  return {
    type: actionTypes.CREATE_KATEGORI_SOAL_SUCCESS,
    data
  }
}

export const actionCreateKategoriSoalFailed = (data) => {
  return {
    type: actionTypes.CREATE_KATEGORI_SOAL_FAILED,
    data
  }
}

export const actionUpdateKategoriSoal = (data) => {
  return {
    type: actionTypes.UPDATE_KATEGORI_SOAL,
    data
  }
}

export const actionUpdateKategoriSoalStart = () => {
  return {
    type: actionTypes.UPDATE_KATEGORI_SOAL_START
  }
}

export const actionUpdateKategoriSoalEnd = () => {
  return {
    type: actionTypes.UPDATE_KATEGORI_SOAL_END
  }
}

export const actionUpdateKategoriSoalSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_KATEGORI_SOAL_SUCCESS,
    data
  }
}

export const actionUpdateKategoriSoalFailed = (data) => {
  return {
    type: actionTypes.UPDATE_KATEGORI_SOAL_FAILED,
    data
  }
}

export const actionResetFormKategoriSoal = () => {
  return {
    type: actionTypes.RESET_FORM_KATEGORI_SOAL
  }
}

export const actionDeleteKategoriSoal = (data) => {
  return {
    type: actionTypes.DELETE_KATEGORI_SOAL,
    data
  }
}

export const actionDeleteKategoriSoalStart = () => {
  return {
    type: actionTypes.DELETE_KATEGORI_SOAL_START
  }
}

export const actionDeleteKategoriSoalEnd = () => {
  return {
    type: actionTypes.DELETE_KATEGORI_SOAL_END
  }
}

export const actionDeleteKategoriSoalSuccess = () => {
  return {
    type: actionTypes.DELETE_KATEGORI_SOAL_SUCCESS
  }
}

export const actionDeleteKategoriSoalFailed = () => {
  return {
    type: actionTypes.DELETE_KATEGORI_SOAL_FAILED
  }
}

export const actionResetDeleteKategoriSoal = () => {
  return {
    type: actionTypes.RESET_DELETE_KATEGORI_SOAL
  }
}