import Immutable from 'seamless-immutable'

const FETCH_UJIAN_PESERTA = 'FETCH_UJIAN_PESERTA'
const FETCH_UJIAN_PESERTA_START = 'FETCH_UJIAN_PESERTA_START'
const FETCH_UJIAN_PESERTA_END = 'FETCH_UJIAN_PESERTA_END'
const FETCH_UJIAN_PESERTA_SUCCESS = 'FETCH_UJIAN_PESERTA_SUCCESS'
const FETCH_UJIAN_PESERTA_FAILED = 'FETCH_UJIAN_PESERTA_FAILED'

const actionTypes = Immutable({
  FETCH_UJIAN_PESERTA,
  FETCH_UJIAN_PESERTA_START,
  FETCH_UJIAN_PESERTA_END,
  FETCH_UJIAN_PESERTA_SUCCESS,
  FETCH_UJIAN_PESERTA_FAILED
})

export default actionTypes
