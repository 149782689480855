import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { alert } from "../../utils/alert"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { request } from "../../utils/request"

const PesertaDaftarUlangDetail = (props) => {
    const { accessToken, match: { params: { id } }, history: { location: { state } } } = props
    const [data, setData] = useState(null)
    const [noData, setNoData] = useState(false)
    const [tampilkanFotoWajah, setTampilkanFotoWajah] = useState(false)
    const [fotoWajahArr, setFotoWajahArr] = useState(null)

    useEffect(() => {
        const titlePage = "Detail Peserta Daftar Ulang"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)

        if (state) {
            setData(state)
        }
        getData()
    }, [])

    useEffect(() => {
        if (data?.user_id && id) {
            if (data?.user_id !== id) {
                getData()
            }
        }
    }, [id])

    const getData = async () => {
        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }
            const res = await request(process.env.REACT_APP_SERVICE_PESERTA_DAFTAR_ULANG + '/' + id, {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                setData(res?.result)
            } else {
                setNoData(true)
            }
        } catch (error) {
            setNoData(true)
            alert({ title: "Gagal", html: "Gagal meminta data ke server" })
        }
    }

    useEffect(() => {
        if (data?.foto_wajah) {
            if (tampilkanFotoWajah === true) {
                if (data?.foto_wajah) {
                    let pars = ""
                    try {
                        pars = JSON.parse(data?.foto_wajah)
                    } catch (e) {
                        pars = [data?.foto_wajah]
                    }
                    if (pars?.length > 0) {
                        setFotoWajahArr(pars)
                    }
                }
            } else {
                setFotoWajahArr(null)
            }
        }
    }, [tampilkanFotoWajah, data?.foto_wajah])

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col-lg-4">
                    <Link to={process.env.REACT_APP_SUBDIR + "/peserta-daftar-ulang"} className="btn btn-white mb-2 btn-md-block">Kembali</Link>
                </div>
                <div className="col-lg-8 text-end">
                    <Link to={process.env.REACT_APP_SUBDIR + "/peserta-daftar-ulang/update/" + data?.user_id + "?from=detail"} className="btn btn-success mb-2 btn-md-block">Ubah</Link>
                </div>
            </div>

            <div className="card mt-3">
                <div className="card-body">
                    {
                        noData === true ?
                            <span>Data tidak ditemukan</span>
                            :
                            <div className="table-responsive text-nowrap detail-view">
                                <table className="table table-bordered">
                                    <tbody className="table-border-bottom-0">
                                        <tr>
                                            <th style={{ width: "25%" }}>
                                                <strong>Nama</strong>
                                            </th>
                                            <td>{data?.user?.name}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ width: "25%" }}>
                                                <strong>NIK</strong>
                                            </th>
                                            <td>{data?.nik}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ width: "25%" }}>
                                                <strong>Nomor HP</strong>
                                            </th>
                                            <td>{data?.nomor_hp}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ width: "25%" }}>
                                                <strong>Email</strong>
                                            </th>
                                            <td>{data?.user?.email}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ width: "25%" }}>
                                                <strong>Sesi Ujian</strong>
                                            </th>
                                            <td>{data?.sesi_ujian?.nama || "-"}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ width: "25%" }}>
                                                <strong>Status Ujian</strong>
                                            </th>
                                            <td>{data?.master_peserta?.status || "-"}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ width: "25%" }}>
                                                <strong>Foto Wajah</strong>
                                            </th>
                                            <td><button type="button" onClick={() => setTampilkanFotoWajah(!tampilkanFotoWajah)} className={"btn btn-sm " + (tampilkanFotoWajah === true ? "btn-danger" : "btn-success")}>{tampilkanFotoWajah === true ? "Sembunyikan" : "Tampilkan"}</button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                    }
                </div>
            </div>

            {
                tampilkanFotoWajah === true ?
                    <div className="row mt-5">
                        {
                            fotoWajahArr?.map((val, key) => {
                                return <React.Fragment key={key}>
                                    <div className="col-md-6 col-xl-4">
                                        <div className="card mb-3">
                                            <img className="card-img-top" src={"data:image/png;base64," + val} alt={"Foto Wajah " + (key + 1)} />
                                            <div className="card-body mb-0">
                                                <p className="card-text">
                                                    <small className="text-muted">Foto {key + 1}</small>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            })
                        }
                    </div>
                    : null
            }
        </div>
    </React.Fragment>
}

const mapStateToProps = state => {
    const { auth: { accessToken } } = state

    return { accessToken }
}

export default withRouter(connect(mapStateToProps, {})(PesertaDaftarUlangDetail))