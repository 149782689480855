import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import readXlsxFile, { readSheetNames } from "read-excel-file"
import FileInput from "../../components/Form/FileInput"
import HeadTable from "../../components/Table/HeadTable"
import { actionImportSoalUjian, actionResetFormSoalUjian } from "../../store/soal-ujian/actions"
import { alert } from "../../utils/alert"
import { implode } from "../../utils/formatter"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { pushUrl } from "../../utils/url"

const SoalUjianImport = (props) => {
    const { accessToken, form, actionImportSoalUjian, actionResetFormSoalUjian } = props

    const [submit, setSubmit] = useState(false)
    const [error, setError] = useState(null)
    const [hasError, setHasError] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState(null)
    const [data, setData] = useState([])
    const [formating, setFormating] = useState(false)

    useEffect(() => {
        const titlePage = "Import Soal Ujian"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)

        actionResetFormSoalUjian()
        return () => actionResetFormSoalUjian()
    }, [])

    const onSubmit = (e) => {
        if (validate() === true) {
            setSubmit(true)

            actionImportSoalUjian({
                payload: data,
                accessToken
            })
        }

        e.preventDefault()
    }

    useEffect(async () => {
        if (submit === true) {
            if (form?.success === true) {
                await alert({ title: "Berhasil", html: form?.message, icon: "success" })
                pushUrl("/soal-ujian")
            } else if (form?.success === false) {
                await alert({ title: "Gagal", html: form?.message, icon: "error" })
            }
        }
    }, [form?.success, submit])

    const validate = () => {
        let hasError = false

        if (!selectedFiles || selectedFiles === "") {
            hasError = true
            setError("File soal ujian tidak boleh kosong")
        }

        if (hasError === true) {
            return false
        } else {
            return true
        }
    }

    const onDrop = async (files) => {
        setFormating(true)
        setHasError(false)
        if (files.length > 0) {
            setSelectedFiles(files)

            let dataFormat = []
            let hasError = false
            let sheetNames = await readSheetNames(files[0])

            await Promise.all(
                sheetNames?.map(async (val, key) => {
                    let rows = await readXlsxFile(files[0], { sheet: key + 1 })

                    let dataSoal = []

                    rows?.map((soal, keySoal) => {
                        if (keySoal >= 3) {
                            let dataErrors = []

                            if (soal[1] === null || soal[1] === "") {
                                dataErrors.push("Pertanyaan tidak boleh kosong")
                            }
                            if (soal[2] === null || soal[2] === "") {
                                dataErrors.push("Jawaban A tidak boleh kosong")
                            }
                            if (soal[3] === null || soal[3] === "") {
                                dataErrors.push("Jawaban B tidak boleh kosong")
                            }
                            if (soal[4] === null || soal[4] === "") {
                                dataErrors.push("Jawaban C tidak boleh kosong")
                            }
                            if (soal[5] === null || soal[5] === "") {
                                dataErrors.push("Jawaban D tidak boleh kosong")
                            }
                            if (soal[6] === null || soal[6] === "") {
                                dataErrors.push("Jawaban E tidak boleh kosong")
                            }
                            if (soal[7] === null || soal[7] === "") {
                                dataErrors.push("Kunci Jawaban tidak boleh kosong")
                            }

                            if (dataErrors?.length > 0) {
                                hasError = true
                            }

                            let pertanyaan = soal[1]
                            if (!pertanyaan.includes('<') && !pertanyaan.includes('>') && !pertanyaan.includes('</')) {
                                pertanyaan = `<p>${pertanyaan}</p>`
                            }

                            dataSoal.push({
                                nomor: soal[0],
                                pertanyaan: pertanyaan,
                                jawaban_a: soal[2],
                                jawaban_b: soal[3],
                                jawaban_c: soal[4],
                                jawaban_d: soal[5],
                                jawaban_e: soal[6],
                                kunci_jawaban: soal[7],
                                errors: dataErrors
                            })
                        }
                    })

                    dataFormat.push({
                        kategori: rows[0][0],
                        soal: dataSoal
                    })
                })
            )

            setData(dataFormat)
            setHasError(hasError)
        }

        setFormating(false)
    }

    useEffect(() => {
        if (hasError === true) {
            let el = document.getElementsByClassName("has-error")

            if (el?.length > 0)
                window.scrollTo({ top: el[0]?.offsetTop + 100, behavior: 'smooth' })
        }
    }, [hasError])


    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col-lg-6">
                    <Link to={process.env.REACT_APP_SUBDIR + "/soal-ujian"} className="btn btn-white mb-2 btn-md-block">Kembali</Link>
                </div>
                <div className="col-lg-6 text-end">
                    <Link to={process.env.REACT_APP_SUBDIR + "/assets/template/master-soal-ujian.xlsx"} className="btn btn-info mb-2 btn-md-block" target="_blank">Download Template</Link>
                </div>
            </div>

            <div className="card mt-3">
                <div className="card-body">
                    <form onSubmit={onSubmit} >
                        <div className="row">
                            <div className="col-lg-12">
                                <FileInput
                                    name={"file"}
                                    label={"File Soal Ujian"}
                                    error={error}
                                    accept={{
                                        'application/vnd.ms-excel': ['.xls'],
                                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                                        'text/comma-separated-values': ['.csv'],
                                        'text/csv': ['.csv']
                                    }}
                                    placeholder={"Pilih file soal ujian"}
                                    selectedFiles={selectedFiles}
                                    onDrop={onDrop}
                                    tabIndex={1}
                                />

                                <button
                                    tabIndex={11}
                                    type="submit" className="btn btn-primary btn-md-block" disabled={hasError || formating === true || form?.loading === true}>
                                    Kirim
                                    {formating === true || form?.loading === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            {
                data?.length > 0 ?
                    < div className="card mt-3">
                        <div className="card-body">
                            <div className="table-responsive grid-view">
                                <table className="table table-bordered">
                                    <HeadTable
                                        colorized={false}
                                        columns={[
                                            { type: "label", label: "No." },
                                            { type: "label", label: "Pertanyaan" },
                                            { type: "label", label: "Jawaban A" },
                                            { type: "label", label: "Jawaban B" },
                                            { type: "label", label: "Jawaban C" },
                                            { type: "label", label: "Jawaban D" },
                                            { type: "label", label: "Jawaban E" },
                                            { type: "label", label: "Kunci" }
                                        ]}
                                    />
                                    <tbody className="table-border-bottom-0">
                                        {
                                            data?.map((val, key) => {
                                                return <React.Fragment key={key}>
                                                    <tr>
                                                        <td colSpan={8}><strong>{val?.kategori}</strong></td>
                                                    </tr>
                                                    {
                                                        val?.soal?.map((soal, keySoal) => {
                                                            return <React.Fragment key={keySoal}>
                                                                <tr>
                                                                    <td rowSpan={soal?.errors?.length > 0 ? 2 : 1}>{soal?.nomor}</td>
                                                                    <td><span dangerouslySetInnerHTML={{ __html: soal?.pertanyaan }}></span></td>
                                                                    <td>{soal?.jawaban_a}</td>
                                                                    <td>{soal?.jawaban_b}</td>
                                                                    <td>{soal?.jawaban_c}</td>
                                                                    <td>{soal?.jawaban_d}</td>
                                                                    <td>{soal?.jawaban_e}</td>
                                                                    <td>{soal?.kunci_jawaban}</td>
                                                                </tr>
                                                                {
                                                                    soal?.errors?.length > 0 ?
                                                                        <tr>
                                                                            <td colSpan={7} className="text-danger has-error">{implode(soal?.errors, ", ")}</td>
                                                                        </tr> : null
                                                                }
                                                            </React.Fragment>
                                                        })
                                                    }
                                                    {
                                                        key + 1 < data?.length ?
                                                            <tr>
                                                                <td colSpan={8}><span className="dash-separator"></span></td>
                                                            </tr> : null
                                                    }
                                                </React.Fragment>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> : null
            }
        </div>
    </React.Fragment >
}

const mapStateToProps = state => {
    const { auth: { accessToken }, soalUjian: { form } } = state

    return { accessToken, form }
}

export default withRouter(connect(mapStateToProps, { actionImportSoalUjian, actionResetFormSoalUjian })(SoalUjianImport))