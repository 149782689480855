import Immutable from 'seamless-immutable'
import actionTypes from './actionTypes'
import { default as actionTypesAuth } from './../auth/actionTypes'

let index = {
  loading: false,
  success: null,
  list: null,
  page: null,
  pageUrl: null,
  last_page: null,
  from: null,
  to: null,
  total: null
}

let form = {
  loading: false,
  success: null,
  payload: null,
  result: null,
  message: null,
  errors: null
}

let del = {
  loading: false,
  success: null
}

let model = {
  index,
  form,
  del
}

export const INITIAL_STATE = Immutable(model)

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Index
    case actionTypes.FETCH_PESERTA_UJIAN_START:
      return {
        ...state,
        index: {
          ...state.index,
          loading: true,
          success: null
        }
      }
    case actionTypes.FETCH_PESERTA_UJIAN_END:
      return {
        ...state,
        index: {
          ...state.index,
          loading: false
        }
      }
    case actionTypes.FETCH_PESERTA_UJIAN_SUCCESS:
      return {
        ...state,
        index: {
          ...state.index,
          success: true,
          list: action?.data?.list,
          page: action?.data?.page,
          pageUrl: action?.data?.pageUrl,
          last_page: action?.data?.last_page,
          from: action?.data?.from,
          to: action?.data?.to,
          total: action?.data?.total
        }
      }

    // Import
    case actionTypes.IMPORT_PESERTA_UJIAN_START:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
          success: null
        }
      }
    case actionTypes.IMPORT_PESERTA_UJIAN_END:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false
        }
      }
    case actionTypes.IMPORT_PESERTA_UJIAN_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          success: true,
          payload: action?.data?.payload,
          result: action?.data?.result,
          message: action?.data?.message,
          errors: null
        }
      }
    case actionTypes.IMPORT_PESERTA_UJIAN_FAILED:
      return {
        ...state,
        form: {
          ...state.form,
          success: false,
          payload: action?.data?.payload,
          result: null,
          message: action?.data?.message,
          errors: action?.data?.errors
        }
      }

    // Create
    case actionTypes.CREATE_PESERTA_UJIAN_START:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
          success: null
        }
      }
    case actionTypes.CREATE_PESERTA_UJIAN_END:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false
        }
      }
    case actionTypes.CREATE_PESERTA_UJIAN_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          success: true,
          payload: action?.data?.payload,
          result: action?.data?.result,
          message: action?.data?.message,
          errors: null
        }
      }
    case actionTypes.CREATE_PESERTA_UJIAN_FAILED:
      return {
        ...state,
        form: {
          ...state.form,
          success: false,
          payload: action?.data?.payload,
          result: null,
          message: action?.data?.message,
          errors: action?.data?.errors
        }
      }

    // Update
    case actionTypes.UPDATE_PESERTA_UJIAN_START:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
          success: null
        }
      }
    case actionTypes.UPDATE_PESERTA_UJIAN_END:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false
        }
      }
    case actionTypes.UPDATE_PESERTA_UJIAN_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          success: true,
          payload: action?.data?.payload,
          result: action?.data?.result,
          message: action?.data?.message,
          errors: null
        }
      }
    case actionTypes.UPDATE_PESERTA_UJIAN_FAILED:
      return {
        ...state,
        form: {
          ...state.form,
          success: false,
          payload: action?.data?.payload,
          result: null,
          message: action?.data?.message,
          errors: action?.data?.errors
        }
      }

    // Delete
    case actionTypes.DELETE_PESERTA_UJIAN_START:
      return {
        ...state,
        del: {
          ...state.del,
          loading: true,
          success: null
        }
      }
    case actionTypes.DELETE_PESERTA_UJIAN_END:
      return {
        ...state,
        del: {
          ...state.del,
          loading: false
        }
      }
    case actionTypes.DELETE_PESERTA_UJIAN_SUCCESS:
      return {
        ...state,
        del: {
          ...state.del,
          success: true
        }
      }
    case actionTypes.DELETE_PESERTA_UJIAN_FAILED:
      return {
        ...state,
        del: {
          ...state.del,
          success: false
        }
      }

    case actionTypes.RESET_FORM_PESERTA_UJIAN:
      return {
        ...state,
        form
      }
    case actionTypes.RESET_DELETE_PESERTA_UJIAN:
      return {
        ...state,
        del
      }

    case actionTypesAuth.LOGOUT:
      return { ...model }
    default:
      return state
  }
}

export default reducer
