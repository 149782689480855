import { combineReducers } from "redux"
import { connectRouter } from 'connected-react-router'
import history from '../utils/history'

import auth from "./auth/reducer"
import sesiUjian from "./sesi-ujian/reducer"
import soalUjian from "./soal-ujian/reducer"
import kategoriSoal from "./kategori-soal/reducer"
import pesertaUjian from "./peserta-ujian/reducer"
import pesertaDaftarUlang from "./peserta-daftar-ulang/reducer"
import ujianPeserta from "./ujian-peserta/reducer"
import peringkatUjian from "./peringkat-ujian/reducer"
import laporanPeserta from "./laporan-peserta/reducer"
import notifikasi from "./notifikasi/reducer"

const rootReducer = combineReducers({
  router: connectRouter(history),
  auth,
  sesiUjian,
  soalUjian,
  kategoriSoal,
  pesertaUjian,
  pesertaDaftarUlang,
  ujianPeserta,
  peringkatUjian,
  laporanPeserta,
  notifikasi
})

export default rootReducer
