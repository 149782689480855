import Immutable from 'seamless-immutable'

const FETCH_PESERTA_DAFTAR_ULANG = 'FETCH_PESERTA_DAFTAR_ULANG'
const FETCH_PESERTA_DAFTAR_ULANG_START = 'FETCH_PESERTA_DAFTAR_ULANG_START'
const FETCH_PESERTA_DAFTAR_ULANG_END = 'FETCH_PESERTA_DAFTAR_ULANG_END'
const FETCH_PESERTA_DAFTAR_ULANG_SUCCESS = 'FETCH_PESERTA_DAFTAR_ULANG_SUCCESS'
const FETCH_PESERTA_DAFTAR_ULANG_FAILED = 'FETCH_PESERTA_DAFTAR_ULANG_FAILED'

const UPDATE_PESERTA_DAFTAR_ULANG = 'UPDATE_PESERTA_DAFTAR_ULANG'
const UPDATE_PESERTA_DAFTAR_ULANG_START = 'UPDATE_PESERTA_DAFTAR_ULANG_START'
const UPDATE_PESERTA_DAFTAR_ULANG_END = 'UPDATE_PESERTA_DAFTAR_ULANG_END'
const UPDATE_PESERTA_DAFTAR_ULANG_SUCCESS = 'UPDATE_PESERTA_DAFTAR_ULANG_SUCCESS'
const UPDATE_PESERTA_DAFTAR_ULANG_FAILED = 'UPDATE_PESERTA_DAFTAR_ULANG_FAILED'

const RESET_FORM_PESERTA_DAFTAR_ULANG = 'RESET_FORM_PESERTA_DAFTAR_ULANG'

const actionTypes = Immutable({
  FETCH_PESERTA_DAFTAR_ULANG,
  FETCH_PESERTA_DAFTAR_ULANG_START,
  FETCH_PESERTA_DAFTAR_ULANG_END,
  FETCH_PESERTA_DAFTAR_ULANG_SUCCESS,
  FETCH_PESERTA_DAFTAR_ULANG_FAILED,

  UPDATE_PESERTA_DAFTAR_ULANG,
  UPDATE_PESERTA_DAFTAR_ULANG_START,
  UPDATE_PESERTA_DAFTAR_ULANG_END,
  UPDATE_PESERTA_DAFTAR_ULANG_SUCCESS,
  UPDATE_PESERTA_DAFTAR_ULANG_FAILED,

  RESET_FORM_PESERTA_DAFTAR_ULANG
})

export default actionTypes
