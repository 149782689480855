import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { actionUpdatePesertaDaftarUlang, actionResetFormPesertaDaftarUlang } from "../../store/peserta-daftar-ulang/actions"
import { alert } from "../../utils/alert"
import { pushUrl } from "../../utils/url"
import { request } from "../../utils/request"
import TextInput from "../../components/Form/TextInput"
import PasswordInput from "../../components/Form/PasswordInput"
import Dropdown from "../../components/Form/Dropdown"

const PesertaDaftarUlangUpdate = (props) => {
    const { actionUpdatePesertaDaftarUlang, actionResetFormPesertaDaftarUlang, accessToken, match: { params: { id } }, history: { location: { state } }, form } = props

    const [payload, setPayload] = useState({
        email: "",
        sesi_ujian_id: "",
        password: ""
    })
    const [errors, setErrors] = useState({})
    const [submit, setSubmit] = useState(false)
    const [noData, setNoData] = useState(false)
    const [from, setFrom] = useState("/peserta-daftar-ulang")
    const [optSesiUjian, setOptSesiUjian] = useState([])
    const [disabled, setDisabled] = useState({})

    useEffect(() => {
        const titlePage = "Ubah Peserta Daftar Ulang"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)

        getSesiUjian()

        // formatPayload(state)
        getData()

        actionResetFormPesertaDaftarUlang()

        return () => actionResetFormPesertaDaftarUlang()
    }, [])

    const getData = async () => {
        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }
            const res = await request(process.env.REACT_APP_SERVICE_PESERTA_DAFTAR_ULANG + '/' + id, {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                formatPayload(res?.result)
                if (res?.result?.master_peserta?.status === "Ikut Ujian") {
                    setDisabled({ sesi_ujian_id: true })
                }
            } else {
                setNoData(true)
            }
        } catch (error) {
            setNoData(true)
            alert({ title: "Gagal", html: "Gagal meminta data ke server" })
        }
    }

    const formatPayload = (data) => {
        setPayload({
            email: data?.user?.email,
            sesi_ujian_id: data?.sesi_ujian_id,
            password: ""
        })
    }

    const getSesiUjian = async () => {
        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }
            const res = await request(process.env.REACT_APP_SERVICE_SESI_UJIAN + '/all-option', {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                setOptSesiUjian(res?.result)
            }
        } catch (error) {
            alert({ title: "Gagal", html: "Gagal meminta data ke server" })
        }
    }

    useEffect(async () => {
        setErrors({})
        if (submit === true) {
            if (form?.success === true) {
                await alert({ title: "Berhasil", html: form?.message, icon: "success" })
                pushUrl(from)
            } else if (form?.success === false) {
                setErrors(form?.errors)
                await alert({ title: "Gagal", html: form?.message, icon: "error" })
            }
        }
    }, [form?.success, submit])

    const onChange = ((field, value) => {
        if (field === "password") {
            payload[field] = value?.slice(0, 10)
        } else {
            payload[field] = value
        }

        setPayload({
            ...payload,
        })
    })

    const onSubmit = (e) => {
        if (validate() === true) {
            setSubmit(true)

            actionUpdatePesertaDaftarUlang({
                id,
                payload,
                accessToken
            })
        }

        e.preventDefault()
    }

    const validate = () => {
        let jsonErrors = {}
        let hasError = false

        if (!payload?.sesi_ujian_id || payload?.sesi_ujian_id === "") {
            hasError = true
            jsonErrors["sesi_ujian_id"] = "Sesi Ujian harus diisi"
        }
        // if (!payload?.password || payload?.password === "") {
        //     hasError = true
        //     jsonErrors["password"] = "Password harus diisi"
        // }

        setErrors(jsonErrors)

        if (hasError === true) {
            return false
        } else {
            return true
        }
    }

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <Link to={process.env.REACT_APP_SUBDIR + from} className="btn btn-white mb-2 btn-md-block">Kembali</Link>

            <div className="card mt-3">
                <div className="card-body">
                    {
                        noData === true ?
                            <span>Data tidak ditemukan</span>
                            :
                            <form onSubmit={onSubmit} >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <TextInput
                                            id={"email"}
                                            name={"email"}
                                            placeholder={"Masukkan email"}
                                            label={"Password"}
                                            onChange={(e) => onChange('email', e?.target?.value)}
                                            required={true}
                                            value={payload?.email}
                                            error={errors?.email}
                                            errors={form?.errors?.email}
                                            tabIndex={1} />

                                        <Dropdown
                                            id={"sesi_ujian_id"}
                                            name={"sesi_ujian_id"}
                                            placeholder={"Pilih sesi ujian"}
                                            label={"Sesi Ujian"}
                                            onChange={(e) => onChange('sesi_ujian_id', e?.target?.value)}
                                            required={true}
                                            value={payload?.sesi_ujian_id}
                                            error={errors?.sesi_ujian_id}
                                            errors={form?.errors?.sesi_ujian_id}
                                            tabIndex={2}
                                            options={optSesiUjian}
                                            disabled={disabled?.sesi_ujian_id} />

                                        <PasswordInput
                                            id={"password"}
                                            name={"password"}
                                            placeholder={"Masukkan password jika ingin mengubah"}
                                            label={"Password"}
                                            onChange={(e) => onChange('password', e?.target?.value)}
                                            required={true}
                                            value={payload?.password}
                                            error={errors?.password}
                                            errors={form?.errors?.password}
                                            tabIndex={3} />

                                        <button
                                            tabIndex={4}
                                            type="submit" className="btn btn-primary btn-md-block" disabled={form?.loading === true}>
                                            Kirim
                                            {form?.loading === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}
                                        </button>
                                    </div>
                                </div>
                            </form>
                    }
                </div>
            </div>
        </div>
    </React.Fragment>
}

const mapStateToProps = state => {
    const { auth: { accessToken }, pesertaDaftarUlang: { form } } = state

    return { accessToken, form }
}

export default withRouter(connect(mapStateToProps, { actionUpdatePesertaDaftarUlang, actionResetFormPesertaDaftarUlang })(PesertaDaftarUlangUpdate))