import Immutable from 'seamless-immutable'
import actionTypes from './actionTypes'
import { default as actionTypesAuth } from './../auth/actionTypes'

let index = {
  loading: false,
  success: null,
  list: null,
  page: null,
  pageUrl: null,
  last_page: null,
  from: null,
  to: null,
  total: null
}

let model = {
  index
}

export const INITIAL_STATE = Immutable(model)

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Index
    case actionTypes.FETCH_LAPORAN_PESERTA_START:
      return {
        ...state,
        index: {
          ...state.index,
          loading: true,
          success: null
        }
      }
    case actionTypes.FETCH_LAPORAN_PESERTA_END:
      return {
        ...state,
        index: {
          ...state.index,
          loading: false
        }
      }
    case actionTypes.FETCH_LAPORAN_PESERTA_SUCCESS:
      return {
        ...state,
        index: {
          ...state.index,
          success: true,
          list: action?.data?.list,
          page: action?.data?.page,
          pageUrl: action?.data?.pageUrl,
          last_page: action?.data?.last_page,
          from: action?.data?.from,
          to: action?.data?.to,
          total: action?.data?.total
        }
      }

    case actionTypesAuth.LOGOUT:
      return { ...model }
    default:
      return state
  }
}

export default reducer
